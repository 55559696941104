/* Render a set of "what would the configured services be" records */
import React from 'react';
import ATXTable from 'atxtable';
import type_renderer from 'reactform/typerenderer';
import { Link } from 'react-router-dom';
import { default_render, with_align_div } from '../reactform/typerenderer';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddCircleRounded from '@material-ui/icons/AddCircleOutlineRounded';
import classNames from 'classnames';

const styles = (theme) => ({
    default: {
        color: 'lightgrey',
    },
    serviceBindings: {
        fontSize: '1rem',
        color: 'darkgrey',
    },
    serviceBinding: {
        paddingBottom: '.25rem',
    },
    serviceType: {
        marginLeft: '1rem',

    },
    typeName: {
        color: 'black',
    },
    serviceTypeName: {
    },
    currentService: {
    }

})
const RoleRender = (props) => {
    const { value, classes, for_resource = true } = props;
    if (!value) {
        return with_align_div({
            field: { name: 'role' },
            title: for_resource ? 'Resource Role (Default)' : 'Service Role (Default)',
            alignment: 'center',
        }, <span className={classes.default}>Default</span>);
    }
    return with_align_div({
        field: { name: 'role' },
        title: for_resource ? 'Resource Role' : 'Service Role',
        alignment: 'center',
    }, <span className={classes.role}>{value}</span>);
};

const ServiceBindingsAsText = withStyles(styles)((props) => {
    const { value, classes, onCreate = null } = props;
    const ordered_services = (services) => {
        return Object.keys(services).sort().map(key => ({
            ...services[key],
            key: key,
        }));
    }

    return <div className={classes.serviceBindings}>
        {value.map((resource, i) => {
            return <div className={classes.serviceBinding} key={`binding-${i}`}>
                <span className={classNames(classes.resourceTypeName, classes.typeName)}>{resource.resource_type_key_display}</span> uses:
                <div className={classes.slots}>
                    {ordered_services(resource.services).map((service, i) => {
                        let current = null;
                        if (service.service) {
                            current = <span className={classes.currentService}>
                                Currently provided by <Link to={service.service.__url__}>{service.service.title}</Link>{' '}
                                part of <Link to={service.service_group.__url__}>{service.service_group.title}</Link>{' '}
                                assigned to <Link to={service.ancestor.__url__}>{service.ancestor.title}</Link>.
                            </span>
                        } else {
                            current = <span className={classes.noService}>Which is currently not assigned.</span>
                        }
                        return <div className={classes.serviceType}>
                            A <span className={classNames(classes.serviceTypeName, classes.typeName)}>{service.service_type_display || service.service_type.name}</span> service.{' '}
                            {current}
                        </div>;
                    })}
                </div>
            </div>
        })}
    </div>;

});
ServiceBindingsAsText.displayName = 'ServiceBindingsAsText';

const ServiceBindings = (props) => {
    const { value, classes, onCreate = null } = props;
    return <ATXTable
        data={value || []}
        showPagination={false}
        filterable={false}
        keyField={'resource_type_key'}
        columns={[
            {
                'Header': 'Resource Type',
                accessor: 'resource_type_key_display',
            },
            {
                'Header': 'Role',
                accessor: 'role',
                Cell: (props) => <RoleRender {...props} classes={classes} />,
            },
            {
                'Header': 'Bindings',
                accessor: 'services',
                Cell: (props) => {
                    const { value } = props;
                    const role = props.row.original.role;
                    const resource = value;
                    const records = Object.keys(value).sort().map(key => value[key]);
                    return <ATXTable
                        data={records || []}
                        showPagination={false}
                        filterable={false}
                        keyField={'key'}
                        columns={[
                            {
                                'Header': 'Config Slot',
                                accessor: 'key',
                            },
                            {
                                'Header': 'Role',
                                accessor: 'service_role',
                                Cell: (props) => <RoleRender {...props} classes={classes} for_resource={false} />,
                            },
                            (onCreate && {
                                'Header': 'Create',
                                id: 'service-create',
                                accessor: 'service_type',
                                Cell: (props) => {
                                    const { value } = props;
                                    const onClick = (evt) => {
                                        onCreate({ 'service_type': value, 'resource_type': resource, 'role': role });
                                        evt.stopPropagation();
                                        evt.preventDefault();
                                    };
                                    return <IconButton
                                        title="Create a new service of this type"
                                        size='small'
                                        onClick={onClick}><AddCircleRounded /></IconButton>;
                                }
                            }) || null,
                            {
                                'Header': 'Config Package',
                                'accessor': 'service_group',
                                'Cell': (props) => {
                                    const { value } = props;
                                    if (value) {
                                        return <Link to={value.__url__}>{value.title}</Link>;
                                    }
                                    return null;
                                }
                            },
                            {
                                'Header': onCreate ? 'Current' : 'Service',
                                accessor: 'service',
                                Cell: (props) => {
                                    const { value } = props;
                                    if (value) {
                                        return <Link to={value.__url__}>{value.title}</Link>;
                                    }
                                    return null;
                                }
                            },
                            {
                                'Header': 'Assigned To',
                                accessor: 'ancestor',
                                Cell: (props) => {
                                    const { value } = props;
                                    if (value) {
                                        return <Link to={value.__url__}>{value.title}</Link>;
                                    }
                                    return null;
                                }
                            },
                        ].filter(x => !!x)}
                    />;
                }
            },
        ]}
    />;
}

export default withStyles(styles)(ServiceBindings);
export { ServiceBindingsAsText };
