import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import generic_filter_rows from 'reactform/genericfilter';
import concrete_columns from './concretecolumns';

import theme from 'dash/theme';
import { useTable, useSortBy, useFilters } from 'react-table';
import useGUIState from 'dash/guistate';
import CssBaseline from '@material-ui/core/CssBaseline';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import "./atx-react-table.css";

import IconButton from '@material-ui/core/IconButton';
import MoreVert from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { PreferenceButton } from 'dash/preferencemenu';

const styles = (theme) => ({
    customizeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    menuItemRadio: {
        color: theme.palette.grey[400],
        marginRight: '.25em',
    },

});


const PreferenceMenu = withStyles(styles)((props) => {
    const { classes, allColumns, initialState, updateGUIState, hideColumnCallback, downloadCallback } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menu_items = [
    ];
    const showing = (name) => {
        if (initialState && initialState.hiddenColumns) {
            if (initialState.hiddenColumns.filter(x => x === name).length) {
                return false;
            }
        }
        return true;
    };
    const toggleColumn = (column) => {
        let new_state = initialState;
        if (showing(column.id)) {
            new_state = {
                ...(initialState || {}),
                hiddenColumns: [
                    ...((initialState && initialState.hiddenColumns) || []),
                    column.id,
                ],
            };
            hideColumnCallback(new_state);
        } else {
            new_state = {
                ...(initialState || {}),
                hiddenColumns: initialState.hiddenColumns.filter(
                    x => x !== column.id
                ),
            };
            hideColumnCallback(new_state);
        }
        updateGUIState(new_state);
        handleClose();
    };
    allColumns.map((column, index) => {
        if (!column.columns) {
            let name = column.name || column.id;
            if (column.form_field && column.form_field.label) {
                name = column.form_field.label;
            }
            else if (typeof column.Header === 'string') {
                name = column.Header;
            }
            const current = showing(column.id);
            menu_items.push(
                <MenuItem
                    onClick={(evt) => toggleColumn(column)}
                    key={`field-${name}-${index}`}
                >{current ? <RadioButtonChecked size='small' className={
                    classes.menuItemRadio
                } /> : <RadioButtonUnchecked size='small' className={
                    classes.menuItemRadio
                } />}
                    {name}
                </MenuItem>
            );
        }
    });
    if (!menu_items.length) {
        return null;
    }
    menu_items.push(
        <Divider key='actions-divider' />
    );
    menu_items.push(
        <MenuItem
            onClick={(evt) => {
                updateGUIState({
                    ...initialState,
                    hiddenColumns: [],
                });
                handleClose();
            }}
            key='all-columns'
        >All</MenuItem>
    );
    menu_items.push(
        <MenuItem
            onClick={(evt) => {
                updateGUIState(null);
                handleClose();
            }}
            key='reset-state'
        >Defaults</MenuItem>
    );
    if (downloadCallback) {
        menu_items.push(
            <Divider key='reset-divider' />
        );
        menu_items.push(
            <MenuItem
                onClick={(evt) => {
                    downloadCallback(evt);
                    handleClose();
                }}
                key='download'
            >Download CSV</MenuItem>
        );
    }

    return <div className={classNames(classes.customizeButton)}>
        <PreferenceButton
            onClick={handleClick}
            title={'Customise table'}
        />
        <Menu
            id="preferences"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >{menu_items}</Menu>
    </div>;
});

export default PreferenceMenu;
