import React, { useEffect } from 'react';
import * as d3 from "d3";
import { apply_accessor } from './accessor';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Legend from './atxlegend';
import { get_graph_data, limit_range } from './extractdataset';

const useStyles = makeStyles((theme) => ({
    buttonHolder: {
        display: 'flex',
    },
    svgHolder: {
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'row',
    },
    svgSizer: {
        flex: 1,
        height: '100%',
        // position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    d3svg: {
        flex: 1,
    },
}));

const extract_count_data = (dataset, graph, final_label = null) => {
    /* Given graph and dataset (storage) get the data necessary for the graph */
    if (!dataset) {
        return []; // No series to render
    }
    if (!final_label) {
        final_label = x => x;
    }
    if (dataset.form_key) {
        dataset = dataset[dataset.form_key];
        if (dataset === null || dataset === undefined) {
            return [];
        }
    }
    const series_map = {};
    const series = [];
    const seen_xes = {};
    const xes = [];

    const { graph_x = 'time', graph_x_scale = (graph.xType == 'time' ? 1000 : 1), graph_y = 'count', label_accessor = graph.split_by } = graph;

    const color_lookup = (r) => {
        if (graph.color_accessor) {
            return apply_accessor(r, graph.color_accessor);
        }
        else if (graph.label_accessor) {
            const label_key = apply_accessor(r, graph.label_accessor);
            return label_key;
        } else {
            return undefined;
        }
    };

    dataset.map(r => {
        const acc_as_array = (acc) => {
            if (!Array.isArray(acc)) {
                return [acc];
            } else {
                return acc;
            }
        }
        const key = acc_as_array(apply_accessor(r, graph.split_by)).join('|');
        const x = apply_accessor(r, graph_x) * graph_x_scale;
        const y = apply_accessor(r, graph_y);
        const label = acc_as_array(apply_accessor(r, graph.label_accessor)).map(
            label => final_label(label)
        ).join(': ');

        const color = color_lookup(r);
        const click_value = graph.click_accessor ? apply_accessor(r, graph.click_accessor) : label;
        let records = series_map[key];
        if (color === undefined) {
            console.log(`Unable to apply ${graph.color_accessor}`);
        }
        if (records === undefined) {
            records = series_map[key] = [];
            series.push(records);
        }
        records.push({
            x: x,
            y: y,
            label: label,
            color: color,
            stack_by: graph.stack_by ? apply_accessor(r, graph.stack_by) : label,
            click_value: click_value,
            click_key: graph.click_accessor ? graph.click_accessor : label_accessor,
        });
        // Following assumes order by xes in the dataset!
        if (!seen_xes[x]) {
            xes.push(x);
            seen_xes[x] = true;
        }
    });
    const x_sort = (first, second) => {
        if (first.x < second.x) {
            return -1;
        } else if (first.x > second.x) {
            return 1;
        } else {
            return 0;
        }
    };
    const label_sort = (first, second) => {
        if (first[0].label < second[0].label) {
            return -1;
        } else if (first[0].label > second[0].label) {
            return 1;
        } else {
            return 0;
        }
    };
    xes.sort();
    series.sort(label_sort);
    // return series;
    const final_series = series.map(s => []);
    series.map(x => x.sort(x_sort));
    xes.map(x => {
        series.map((records, index) => {
            const final_index = final_series[index].length;
            if (records.length && records[0].x <= x) {
                const record = records.shift();
                record.index = final_index;
                final_series[index].push(record);
            } else {
                const record = final_series[index].length ? final_series[index][final_series[index].length - 1] : records[0];
                final_series[index].push({
                    ...record,
                    x: x,
                    y: 0,
                    label: record.label,
                    color: record.color,
                    index: final_index,
                    stack_by: record.stack_by,
                });
            }
        });
    });
    return final_series;
};

const calculate_domains = (graph_data, y_scale = 1.0, stack_by = null) => {
    /* Calculate min/max x/y domains for all points in graph_data */
    let min_x, min_y, max_x, max_y = undefined;
    const x_stacks = {}
    if (stack_by) {
        /* Caclulate totals */
        graph_data.map(recordset => {
            recordset.map(record => {
                let current = x_stacks[record.x];
                let total = 0;
                if (current === undefined) {
                    x_stacks[record.x] = current = {};
                } else {
                    total = current[record.stack_by] || 0;
                }
                current[record.stack_by] = total + record.y;
            });
        });
        /* Scale the grouped-by elements by totals */
        graph_data.map(recordset => {
            recordset.map(record => {
                let total = x_stacks[record.x][record.stack_by];
                if (total) {
                    record.y = record.y / total;
                }
            });
        });
    }
    const last_y_map = {};
    graph_data.map(recordset => {
        recordset.map(record => {
            if (min_x === undefined) {
                min_x = record.x;
            } else {
                if (record.x < min_x) {
                    min_x = record.x;
                }
            }
            let offset = last_y_map[record.x] || 0;
            last_y_map[record.x] = offset + record.y;

            if (max_x === undefined) {
                max_x = record.x;
            } else {
                if (record.x > max_x) {
                    max_x = record.x;
                }
            }
        });
    });
    max_y = d3.max(Object.values(last_y_map));
    return [[min_x, max_x], [0, max_y * y_scale]];
};

const nearest_record = (recordset, date) => {
    /* Find nearest record in recordset to given date (x coordinate) */

    console.log(`Query for date ${(new Date(date * 1000))}`);
    const deltas = recordset.map((record, index) => [Math.abs(record.x - date), record]);
    deltas.sort((a, b) => a[0] < b[0] ? -1 : 1);
    const record = deltas[0][1];
    console.log(`  Nearest date ${(new Date(record.x * 1000))} ${deltas[0]} seconds off`);
    return record;
};


const ATXStackedArea = (props) => {
    const svgRef = React.useRef(null);
    const holderRef = React.useRef(null);
    const classes = useStyles();
    const [tooltip, setTooltip] = React.useState(null);
    const [highlight, setHighlight] = React.useState(null);
    let { width = 600, height = 150 } = props;
    const {
        xType = 'time',
        opacity = .2,
        series,
        graph,
        metric,
        updateContext = null,
        storage = null,
        need_extraction = true,
        link_callback = null,
        axis_font_size = '.75rem',
    } = props;
    let graph_data = get_graph_data(series);
    const final_label = (value) => {
        const labels = graph.labels || metric.labels;
        const custom = labels && labels[value];
        if (custom !== undefined) {
            return custom;
        }
        return value;
    };

    if (need_extraction) {
        graph_data = extract_count_data(graph_data, graph, final_label);
    } else {
        graph_data = series;
    }
    const labels = graph_data.map(records => ((records && records.length && records[records.length - 1].label) || '0'));

    const date_format = d3.timeFormat('%Y-%m-%d %H:%M');

    let color_scale = d3.scaleOrdinal();
    if (metric && metric.color_map) {
        const old_scale = color_scale;
        color_scale = (index) => {
            const label = graph_data[index][0].color;
            let color = metric.color_map[label];
            if (color != undefined) {
                return color;
            }
            console.log(`Unmapped color: ${label}`);
            return old_scale(index);
        };
    }
    const legends = graph_data.map((recordset, index) => {
        if (!recordset.length) {
            return null;
        }
        const record = recordset[0];
        return {
            title: final_label(record.label || record.title || record.name),
            color: color_scale(index),
            click_value: record.click_value,
            click_key: record.click_key,
            __key__: index,
        };
    });
    legends.reverse();


    const highlighted = (key) => {
        const base = (highlight !== null) ? (highlight == key ? true : false) : true;
        return base;
    };

    useEffect(() => {
        if (!(svgRef.current && holderRef.current)) {
            console.info(`No svg reference, so cannot render with d3`);
            return;
        }
        const dimensions = {
            'width': holderRef.current.clientWidth,
            'height': holderRef.current.clientHeight,
            'margins': {
                left: limit_range(holderRef.current.clientHeight / 8, 40, 64),
                right: holderRef.current.clientHeight / 12,
                top: 0,
                bottom: limit_range(holderRef.current.clientHeight / 10, 40, 64),
            },
            'axisMargin': 5,
        };
        if ((!dimensions) || dimensions.width < 1 || dimensions.height < 1) {
            console.info(`Holder element has no current size`);
            return;
        }
        let [x_domain, y_domain] = calculate_domains(graph_data, y_scale = 1.05, graph.stack_by);
        let date_domain = x_domain.map(x => (new Date(x * 1000)));
        svgRef.current.style.width = dimensions.width;
        svgRef.current.style.height = dimensions.height;

        const svg = d3
            .select(svgRef.current)
            .classed("line-chart", true)
            .attr('view-box', `0 0 ${dimensions.width} ${dimensions.height}`)
            // .attr("width", dimensions.width)
            // .attr("height", dimensions.height)
            ;
        svg.selectAll('*').remove();
        const container_height = dimensions.height - (dimensions.margins.top + dimensions.margins.bottom);
        const container_width = dimensions.width - (dimensions.margins.left + dimensions.margins.right);
        const container = svg
            .append("g")
            .attr("width", container_width)
            .attr("height", container_height)
            .classed("container", true)
            .attr("transform", `translate(${dimensions.margins.left}, ${dimensions.margins.top})`);

        let time_scale = d3.scaleTime().domain(date_domain).range([0, container_width]).nice();
        let x_axis = d3.axisBottom(time_scale);

        let y_scale = d3.scaleLinear().domain(y_domain).range([container_height, 0]);
        let y_axis = d3.axisLeft(y_scale);

        if (dimensions.width < 600) {
            x_axis = x_axis.ticks(4);
        }
        if (dimensions.height < 200) {
            y_axis = y_axis.ticks(4);
        }


        container.append('g')
            .call(d3.axisTop(time_scale).tickSize(-container_height).tickFormat('')).selectAll(
                '.tick line'
            ).style('stroke', '#c0c0c0');
        container.append('g')
            .call(d3.axisLeft(y_scale).tickSize(-container_width).tickFormat('')).selectAll(
                '.tick line'
            ).style('stroke', '#c0c0c0');
        container.selectAll('.domain').remove();
        container.append('g').classed('primary-graph');
        svg.append("g")
            .attr("transform", `translate(${dimensions.margins.left},${container_height + dimensions.margins.top + dimensions.axisMargin})`)
            .call(x_axis)
            .selectAll(".tick text")
            .attr("font-size", axis_font_size);
        svg.append("g")
            .attr("transform", `translate(${dimensions.margins.left - dimensions.axisMargin},${dimensions.margins.top})`)
            .call(y_axis)
            .selectAll(".tick text")
            .attr("font-size", axis_font_size);

        // Now draw the actual data here...
        // svg.area().x(

        // );


        if (graph_data) {
            // console.log(graph_data);
            const last_y_map = {};
            graph_data.map((recordset, set_index) => {
                // generate records and offset each X's y offset by last value...
                let tops = [], bottoms = [];
                recordset.map(record => {
                    const rec_x = record.x;
                    const offset = last_y_map[rec_x] || 0;
                    const top = offset + record.y;
                    const time_x = new Date(rec_x * 1000);
                    bottoms.push([time_scale(time_x), y_scale(offset)]);
                    tops.push([time_scale(time_x), y_scale(top)]);
                    last_y_map[rec_x] = top;
                });
                bottoms.reverse();
                const pointset = ([...tops, ...bottoms].map(r => `${r[0]},${r[1]}`)).join(' ');
                // console.log(`tops ${tops.length} bottoms ${bottoms.length} points ${JSON.stringify(pointset)}`);
                const formatted = `M ${pointset} Z`;
                const path = container.append('path')
                    .attr('d', formatted)
                    .attr('fill', color_scale(set_index))
                    .attr('opacity', (highlight !== null) ? (highlight == set_index ? 1 : .5) : 1)
                    // .attr('stroke', r => (highlight != null && highlighted(set_index)) ? 'black' : null)
                    .on('mousemove', function (d, i) {
                        if (set_index != highlight) {
                            setHighlight(set_index);
                        }
                        const date = time_scale.invert(d.offsetX).getTime() / 1000 - 3600;
                        const record = nearest_record(recordset, date);
                        const record_date = new Date((record.x - (record.x % 60)) * 1000);
                        let value = record.y;
                        if (typeof value === 'number') {
                            value = Math.round(value * 100) / 100;
                        }
                        const new_tooltip = `${date_format(record_date)} => ${record.label} = ${value}`;
                        if (new_tooltip != tooltip) {
                            setTooltip(new_tooltip);
                        }

                    })
                    // .classed(classes.highlight, (highlight !== null) ? (highlight == set_index ? true : false) : true)
                    .on('mouseout', function (d, i) {
                        setHighlight(null);
                        setTooltip(null);
                    })
                    .on('click', function (d, i) {
                        if (link_callback != null) {
                            const date = time_scale.invert(d.offsetX).getTime() / 1000;
                            const record = nearest_record(recordset, date);
                            // Need datapoint, key, series
                            link_callback({
                                ...record,
                                key: record.click_key,
                                value: record.click_value,
                            }, record.click_key, recordset);
                        }
                    })
                    ;

            });
        }


    }, [graph_data, graph]);

    const legend_click = link_callback ? (record) => {
        link_callback({
            value: record.click_value,
            label: record.title || record.label,
        }, record.click_key, graph_data);
    } : null;

    return <div className={classes.svgHolder} placement="top" >
        <div className={classes.svgSizer} style={{ height: height }} ref={holderRef}>
            <Tooltip title={tooltip || null} followCursor>
                <svg ref={svgRef} className={classes.d3svg} />
            </Tooltip>
        </div>
        <Legend style={{ height: height }} legends={legends} highlight={highlight} setHighlight={setHighlight} on_click={legend_click} />
    </div>;
};


export default ATXStackedArea;
export { extract_count_data, calculate_domains, nearest_record, ATXStackedArea };