/* type-rendering functions for use in display-only contexts */
import React from 'react';
import AlignDiv from './aligndiv';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FocusLink from 'dash/focuslink';
import PropTypes, { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { human_duration_text } from 'humanize';
import wraps from 'wraps';
import Icon from '@material-ui/core/Icon';
import EllipsisText from './ellipsistext';
import PopUpTextRender from './popuptext';

const fields_changed = (first, second, fields) => {
    return fields.filter((k) => {
        return first[k] !== second[k];
    }).length == 0;
};

function with_render_catch(Component) {
    /* HOC to wrap Component with information error message handling for type renderers */
    class WithRenderCatch extends React.Component {
        state = {
            'hasError': false,
        };
        static defaultProps = {
            type_name: 'unknown',
            value: null,
            record: null,
            field: null,
        };
        componentDidCatch(error, info) {
            this.setState({
                'hasError': true,
                'error': error,
                'info': info,
            });
            const { type_name, value, record, field } = this.props;
            console.error(`Failure rendering ${type_name} on ${JSON.stringify(value)} for ${(field && field.name) || 'unknown'}`);
        }
        render() {
            if (this.state.hasError) {
                const { type_name, value, field } = this.props;
                return <div className="error" title="An error occurred rendering this value">{`${value}`}</div>;
            }
            return <Component {...this.props} />;
        }
    }
    return wraps(WithRenderCatch, Component, 'with_render_catch');
}
const render_value = (value, record, field_name, props = null, show_link = true) => {
    /* render the given field */
    const display_key = `${field_name}_display`;
    const url_key = `${field_name}_url`;
    const icon_key = `${field_name}_icon`;
    let display = null;
    let link = null;
    let icon = null;
    if (value === undefined || value === null) {
        return null;
    }
    if (show_link && record[url_key]) {
        link = record[url_key];
    }
    if (record[icon_key]) {
        icon = record[icon_key];
    }

    let color_map = props.field.params && props.field.params.color_map;
    let label_map = props.field.params && props.field.params.labels;
    let help_map = props.field.params && props.field.params.help_map;
    let color = null;
    let help = null;

    if (record[display_key]) {
        display = record[display_key];
    } else if (Array.isArray(value)) {
        display = expand_array(value, props || {}, link);
        link = null;
    } else {

        if (record && record[display_key]) {
            display = record[display_key];
        } else if (value && value.title) {
            display = value.title;
        } else if (value && value.friendly_name) {
            display = value.friendly_name;
        } else if (value && value.name) {
            display = value.name;
        } else {
            if (typeof value === 'string') {
                const multiline = /[\r\n]/;
                const lines = value.split(multiline).filter(x => (x.trim().length > 1));
                if (lines.length > 1) {
                    display = <div title={value}>{lines[0]}</div>;
                } else {
                    display = value;
                    if (label_map && label_map[value]) {
                        display = label_map[value];
                    }
                    if (color_map && color_map[value]) {
                        color = color_map[value];
                        if (help_map && help_map[value]) {
                            help = help_map[value];
                        }
                        display = <span style={{ color: color }} title={help}>{display}</span>;
                    }
                }
            } else {
                display = `${value}`;
                if (help_map && help_map[value]) {
                    help = help_map[value];
                }

                if (label_map && label_map[value]) {
                    display = label_map[value];
                }
                if (color_map && color_map[value]) {
                    color = color_map[value];
                    display = <span style={{ color: color }} title={help}>{display}</span>;
                }
                if (display == '[object Object]') {
                    // display = `${JSON.stringify(value)}`;
                    if (value && value.id) {
                        display = `#${value.id}`;
                    }
                    if (value && value.help) {
                        help = value.help;
                    }
                }
            }
        }
    }
    if (value && value.__icon__) {
        icon = value.__icon__;
    }
    if (icon) {
        if (typeof icon == "string") {
            icon = <Icon fontSize={"small"} size="small" key="icon" title="Record icon" aria-hidden={true}>{icon}</Icon>;
        } else if (typeof icon == "function") {
            icon = icon(value, record, field_name, props);
        }
        if (icon) {
            display = <React.Fragment>{icon}{' '}{display}</React.Fragment>;
        }
    }
    if (show_link && link) {
        return <FocusLink focus_on={value} title='Edit the linked record' to={link}>{display}</FocusLink>;
    } else if (show_link) {
        return add_link(value, display);
    } else {
        return display;
    }


};

const default_render = (props) => {
    /* Default value renderer, null (empty), otherwise display value or otherwise stringify */
    const { value, record, field, alignment, classes } = props;
    const display = render_value(value, record, field.name, props);
    return with_align_div({
        ...props,
        title: props.title || props.label || (props.field && props.field.label) || null,
    }, display);
};
const DefaultRender = React.memo(default_render, (first, second) => fields_changed(first, second, ['value', 'title', 'label', 'field', 'record', 'alignment']));
const expand_array = (value, props, links = null) => {
    const { record, field, alignment, classes } = props;
    const render_one = (item, index) => {
        if (item && item.title) {
            return add_link(item, item.title);
        } else if (item && (links && links[index])) {
            return <Link to={links} key={`item-${index}`} title='Click to edit the linked record'>{item}</Link>;

        } else {
            return default_render({ ...props, value: item });
        }
    };
    return <List dense={true} disablePadding={true}>
        {
            value.map((item, i) => <ListItem style={{ paddingTop: 0, paddingBottom: 0 }} dense={true} key={i}>{render_one(item, i)}</ListItem>)
        }
    </List>;
};

const suppress_bubble = (evt) => {
    evt.stopPropagation();
    return false;
};


const SuppressLink = (props) => {
    const { link, title = 'Click to download the file', text = 'Download' } = props;
    return <div
        className='suppress-link' onClick={suppress_bubble}
    >
        <a href={link} target="_blank" title={title}> {text}</a >
    </div>;
};

const add_link = (value, display) => {
    let link = null;
    if (value) {
        if (value && value.__url__) {
            link = value.__url__;
        } else if (value && value.url) {
            link = value.url;
        }
    }
    if (link && value && value.__type__ && value.__type__ == 'File') {
        return <SuppressLink link={link} title={`Click to download the file ${value.__url__}`} text='Download' />;
    }
    if (link) {
        return <div
            className='suppress-link' onClick={suppress_bubble}
        ><FocusLink
            focus_on={value}
            key={value.id}
            to={link}
            title={`Click to edit the linked record ${value.title || value.friendly_name || value.name}`}
        >{display}</FocusLink></div>;
    } else {
        return display;
    }
};
const with_align_div = (props, rendered) => {
    const { field, title, label } = props;
    const display_key = `${field.name}_display`;
    return <AlignDiv
        label={title || label || (field && field.label) || null}
        key={display_key}
        alignment={props.alignment || 'center'}
    >{rendered}</AlignDiv>;
};

const REGISTRY = {};

function type_renderer(type_name) {
    if (typeof type_name === 'function') {
        return type_name;
    }
    // if (!type_name) {
    //     console.log(`Type name ${type_name}`);
    // }
    return REGISTRY[type_name];
}
function register(type_name, func) {
    REGISTRY[type_name] = with_render_catch(func);
    return func;
}
register('', DefaultRender);
register('DisplayWidget', DefaultRender);


const timestamp_render = (props) => {
    const { value, title, label, field } = props;
    let display = '';
    if (value) {
        const delta = (new Date().getTime() / 1000) - value;
        const delta_text = human_duration_text(delta);
        const value_date = new Date(value * 1000);
        display = <span title={`${value_date.toDateString()} ${value_date.toTimeString()}`}>{delta_text}</span>;
    }
    return with_align_div({
        ...props,
        title: title || label || (field && field.label) || null,
    }, display);
};
register('Timestamp', timestamp_render);
const timestamp_full = React.memo((props) => {
    const { value, title, label, field } = props;
    if (!value) {
        return null;
    }
    const d = new Date(value * 1000);
    const pad = (x) => {
        if (x < 10) {
            return `0${x}`;
        } else {
            return `${x}`;
        }
    };
    const display = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(Math.round(d.getSeconds()))}`;
    return with_align_div({
        ...props,
        title: title || label || (field && field.label) || null,
    }, display);

}, (first, second) => fields_changed(first, second, ['value', 'title', 'label', 'field'])
);
register('TimestampDate', timestamp_full);
const level_block = (props) => {
    const { value, title, field } = props;
    const color_map = field.params && field.params.color_map;
    const label_map = field.params && field.params.labels;
    const label = label_map[value];


    const display = <div style={{ color: color_map[value], width: '100%', height: '100%' }}>⬤ {label}</div>;
    return with_align_div({
        ...props,
        title: title || label || (field && field.label) || null,
    }, display);

};
register('LevelColor', level_block);

const EllipsisRender = (props) => {
    const { value, record, field, alignment, classes } = props;
    const display = render_value(value, record, field.name, props);
    return with_align_div({
        ...props,
        title: props.title || props.label || (props.field && props.field.label) || null,
    }, <EllipsisText value={display} />);
};
register('Ellipsis', EllipsisRender);

const FileDownloadRender = (props) => {
    const { value, record, field, alignment, classes } = props;
    let display = null;
    if (value) {
        const target_url = value.url || value.__url__;
        if (target_url) {
            display = <SuppressLink link={target_url} title={`Click to download the file`} text='Download' />;
        } else {
            display = 'No URL';
        }
    } else {
        return null;
    }
    return with_align_div({
        ...props,
        title: props.title || props.label || (props.field && props.field.label) || null,
    }, display);
};
register('FileDownload', FileDownloadRender);

register('PopUpText', PopUpTextRender);

const CellRender = (props) => {
    /* Strip out values that are not compatible with renderers */
    const { classes, Header, ...childProps } = props;
    const type = props.__type__ || props.column.__type__ || '';
    const value = props.value;
    const Component = type_renderer(type);
    Component.displayName = Component.displayName || `TypeRender.${type}`;
    return <Component
        {...childProps}
        type={type}
        value={value}
        field={{
            name: props.column.id,
            label: (props.label || props.column.Header),
        }}
    />;
};
CellRender.propTypes = {
    __type__: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    column: PropTypes.shape({
        name: PropTypes.string,
        // Header: PropTypes.oneOf([PropTypes.string,PropTypes.func]),
    }).isRequired,
};

export default type_renderer;
export { type_renderer, register, with_align_div, add_link, default_render, render_value, DefaultRender, CellRender, timestamp_render, timestamp_full };
