import React from 'react';
import {
    Hint,
    RadialChart,
    DiscreteColorLegend,
} from 'react-vis';
import { query_color_map } from 'dash/charts/colormap';
import COLORS from './colors';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Legend from './atxlegend';
import * as d3 from "d3";
import { extract_data } from 'dash/charts/extractdataset';
import { graph_format, graph_metric, graph_key } from 'dash/charts/chartmodel';
import { extract_dataset, threshold_stacking } from './extractdataset';
import apply_accessor from './accessor';

const sortFunction = new Intl.Collator('en', { 'sensitivity': 'base' });

const useStyles = makeStyles((theme) => ({
    svgHolder: {
        display: 'flex',
    },
    svgSizer: {
        display: 'flex',
        flex: '1',
    },
    d3svg: {
        flex: 1,
        // minHeight: '300px',
    },
}));


const ATXPieChart = (props) => {
    /* Create a pie-chart from the result of a category (count) query */
    const svgRef = React.useRef(null);
    const holderRef = React.useRef(null);
    const classes = useStyles();
    const [tooltip, setTooltip] = React.useState(null);
    const [highlight, setHighlight] = React.useState(null);
    const {
        width = 150,
        height = 150,
        opacity = .2,
        graph,
        format,
        updateContext = null,
        need_extraction = true,
        link_callback = null,
        metric = null,
        color_map = {
            'false': '#ffff0060',
            'true': '#00007760',
            'null': '#cccccc60',
        },
        colors = [
            // Chosen to match the plotly js colours
            '#1f77b460',  // muted blue
            '#ff7f0e60',  // safety orange
            '#2ca02c60',  // cooked asparagus green
            '#d6272860',  // brick red
            '#9467bd60',  // muted purple
            '#8c564b60',  // chestnut brown
            '#e377c260',  // raspberry yogurt pink
            '#7f7f7f60',  // middle gray
            '#bcbd2260',  // curry yellow-green
            '#17becf60'   // blue-teal
        ],
    } = props;
    let { innerRadius = null } = props;
    // console.log(`Raw series: ${JSON.stringify(props.series)}`);
    let extracted_series = null;
    if (graph.modern_extraction) {
        let stacking = graph.stacking;
        if ((!stacking) && graph.query_type == 'threshold') {
            stacking = threshold_stacking(graph);
        }
        extracted_series = extract_dataset(props.series, graph.extraction, stacking, graph.binning, graph.sorting);
    } else {
        if (graph.need_extraction === undefined || graph.need_extraction) {
            extracted_series = extract_data(graph, props.series, { ...(metric || {}), ...graph }, format || graph_format(graph));
        } else {
            extracted_series = props.series;
        }
    }

    /* We need to assign a color to each extracted data-element */
    const color_for_record = (record, r_index) => (
        record.color
        || query_color_map(record.__key__, color_map)
        || colors[r_index % (colors.length)]
    );

    const series = extracted_series.map(recordset => recordset.map(record => ({
        ...record,
        color: color_for_record(record),
    })));
    series.sort((a, b) => sortFunction.compare(a.label, b.label));
    const legends = series.flat();
    const legend_click = link_callback ? (record) => {
        link_callback({
            value: record.grouped_by,
            label: record.label,
        }, record.filter_key ? apply_accessor(record.filter_key) : (record.accessor || metric.accessor), series);
    } : null;
    const highlighted = (key) => {
        const base = (highlight !== null) ? (highlight == key ? true : false) : true;
        return base;
    };

    React.useEffect(() => {
        if (!(svgRef.current && holderRef.current)) {
            console.info(`No svg reference, so cannot render with d3`);
            return;
        }
        const dimensions = {
            'width': holderRef.current.clientWidth,
            'height': holderRef.current.clientHeight,
            'margins': {
                left: 5,
                right: 5,
                top: 5,
                bottom: 5,
            },
            'axisMargin': 5,
        };
        let use_guage = false;
        if (graph.use_guage || (graph.guage_when_wide && dimensions.width > (dimensions.height * 2))) {
            use_guage = true;
        }
        const svg = d3
            .select(svgRef.current)
            .classed("pie-chart", true)
            .attr('view-box', `0 0 ${dimensions.width} ${dimensions.height}`)
            ;
        svg.selectAll('*').remove();
        if (!series.filter(x => x.length).length) {
            return;
        }
        const container_height = dimensions.height - (dimensions.margins.top + dimensions.margins.bottom);
        const container_width = dimensions.width - (dimensions.margins.left + dimensions.margins.right);
        const container = svg
            .append("g")
            .attr("width", container_width)
            .attr("height", container_height)
            .classed("container", true)
            .attr("transform", `translate(${dimensions.margins.left}, ${dimensions.margins.top})`);

        let radius = Math.min(container_height, container_width) / 2 - (dimensions.axisMargin * 2);
        if (use_guage) {
            radius *= 2;
        }

        let current_radius = radius;
        innerRadius = props.innerRadius || (radius / Math.max(series.length + 1, 2));
        let ring_padding = 0;
        if (series.length > 1) {
            ring_padding = radius / 50;
        }
        let step = ((radius - innerRadius) / (series.length || 1)) - ring_padding;

        series.map(recordset => {
            let pieGenerator = d3.pie()
                .value((record) => {
                    return record.value;
                })
                .padAngle(.015)
                .sort(null)
                ;
            if (use_guage) {
                pieGenerator = pieGenerator
                    .startAngle(() => -.5 * Math.PI)
                    .endAngle(() => .5 * Math.PI)
                    ;

            }
            const arcData = pieGenerator(recordset);
            const arcGenerator = d3.arc()
                .innerRadius(current_radius - step)
                .outerRadius(current_radius)
                ;
            const centering = (dimensions.width - (2 * (radius + dimensions.axisMargin))) / 2;
            const arcHolder = container.append('g')
                // .attr("transform", `translate(-${dimensions.axisMargin + radius},-${dimensions.axisMargin + radius})`)
                .classed("arc-holder", true)
                .attr("transform", `translate(${radius + dimensions.axisMargin + centering}, ${(use_guage ? radius : radius) + dimensions.axisMargin})`)
                ;

            arcHolder.selectAll('.path')
                .data(arcData)
                .enter()
                .append('path')
                .attr('d', arcGenerator)
                .attr('fill', r => r.data.color)
                .attr('opacity', r => highlighted(r.data.__key__) ? 1 : .5)
                .attr('stroke', r => (highlight != null && highlighted(r.data.__key__)) ? 'black' : null)
                .classed('pie-segment', true)
                .on('mousemove', function (evt, record) {
                    const key = record.data.__key__;
                    if (key != highlight) {
                        setHighlight(key);
                    }
                })
                .on('mouseout', function (evt, record) {
                    setHighlight(null);
                })
                .on('click', function (evt, record) {
                    const data = record.data;
                    link_callback({
                        value: data.grouped_by,
                        label: data.label,
                    }, metric.accessor, series);
                })
                ;
            arcHolder.selectAll('.pie-label')
                .data(arcData)
                .enter()
                .append('text')
                .text(function (d) {
                    return `${d.data.value}`;
                })
                .attr("transform", function (d) { return "translate(" + arcGenerator.centroid(d) + ")"; })
                .style("text-anchor", "middle")
                .style("font-size", '1em')
                .style('pointer-events', 'none')
                .classed('pie-label', true)
                // .attr('opacity', r => (highlight!=null&& highlighted(r.data.__key__) ? 1 : 0))
                ;
            current_radius -= (step + ring_padding);
        });
    }, [highlight, series]);

    return <div className={classes.svgHolder} placement="top" >
        <div className={classes.svgSizer} ref={holderRef}><svg style={{ width: width, height: height }} ref={svgRef} className={classes.d3svg} /></div>
        <Legend style={{ height: height }} legends={legends} highlight={highlight} setHighlight={setHighlight} on_click={legend_click} />
    </div>;
};

export default ATXPieChart;
