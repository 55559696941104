import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation, withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { FocusContext } from 'dash/focusprovider';
import { TargetContext } from 'dash/resolvetarget';

import MuiButton from 'dash/MuiButton';
import MuiTypography from 'dash/MuiTypography';
import DocumentTitle from 'dash/documenttitle';
import UserName from 'dash/username';
import { useCurrentUser } from 'withcurrentuser';
import { ActiveRouteContext, reverse } from 'dash/navigationUtils';
import Config from 'config';
import redirect from 'redirect';
import { PAGE_FOCUS } from 'storages';
import ChildSelect from 'dash/childselecttabular';
import icon_for_type from 'typeicon';

const styles = theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: theme.palette.common.white,
        color: theme.palette.common.text,
        justifyContent: 'center'
    },
    appBarShift: {
        width: `100%`
    },
    logo: {
        height: '45px',
        marginRight: theme.spacing(2)
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1,
        flex: 1,
    },
    childSelectWrapper: {
        display: 'flex',
        flexDirection: 'row',
        '&>span': {
            paddingRight: '.25em',
        }
    },
    childSelect: {
        flexGrow: 1,
        flex: 1,
    }
});

const AncestorLink = (props) => {
    /* Link to an ancestor view-specific metadata properties */
    const { ancestor, ...restProps } = props;
    const url = reverse({ target: ancestor, ...restProps });
    if (ancestor.__pk__ == 0) {
        console.log(`Calculate url for root ${url}`);
    }
    const { title } = props;
    if (url) {
        return <Link to={url}>{(ancestor && ancestor.__pk__ && icon_for_type(ancestor.__type__)) || null} {title || ancestor.title}</Link>;
    } else {
        return <span>{title || ancestor.title}</span>;
    }
};

const AppHeader = (props) => {
    const { classes, location, routes, base_url, match, history } = props;
    const { activeRoute, activeRoutes } = React.useContext(ActiveRouteContext);
    const user = useCurrentUser();
    const focus = React.useContext(FocusContext);
    const target = React.useContext(TargetContext);

    const title_prop = (activeRoute.title || activeRoute.header);
    var title = title_prop && (
        typeof title_prop === 'string' ? title_prop : title_prop(
            { 'route': activeRoute, 'match': match, location: location, target: target }
        )
    );
    if (title === undefined || title === null) {
        title = 'Unknown Location';
    }
    var ancestor_list = [];
    // activeRoutes && activeRoutes.slice(1, activeRoutes.length).map((route, i) => {
    //     let parent = null;
    //     // Have to fix this...
    //     if (route.link_url) {

    //         parent = <Link key={`menu-$i`} to={route.link_url}>{route.title}</Link>;
    //     } else if (route.title) {
    //         parent = <div key={`menu-$i`}>{route.title}</div>;
    //     }
    //     if (parent) {
    //         ancestor_list.push(parent);
    //     }
    // });
    if (!Config.world_is_node) {
        if (focus) {
            // console.info(`Re-rendering the header ${focus.__pk__}`);
            const { ancestors } = focus;
            if (ancestors) {
                ancestor_list.push(
                    <AncestorLink
                        ancestor={{
                            __type__: 'Group',
                            __pk__: 0,
                            title: icon_for_type('World'),
                        }}
                        activeRoute={activeRoute}
                        routes={routes}
                        user={user}
                        match={match}
                        key={`ancestor-${-1}`}
                    />
                );
                ancestors.map((ancestor, i) => {
                    ancestor_list.push(
                        <AncestorLink
                            ancestor={ancestor}
                            activeRoute={activeRoute}
                            routes={routes}
                            user={user}
                            match={match}
                            key={`ancestor-${i}`}
                        />
                    );
                });
            }
            // ancestor_list.push(
            //     <AncestorLink
            //         ancestor={focus}
            //         activeRoute={activeRoute}
            //         routes={routes}
            //         user={user}
            //         match={match}
            //         // title={title}
            //         key={`self-link`}
            //     />
            // );
            ancestor_list.push(
                <ChildSelect
                    target={focus}
                    activeRoute={activeRoute}
                    activeRoutes={activeRoutes}
                    routes={routes}
                    user={user}
                    match={match}
                    // title={title}
                    key={`children-choice`}
                    className={classes.childSelect}
                />
            );
        } else {
            ancestor_list.push(<ChildSelect
                target={{
                    __type__: 'World',
                    __pk__: 0,
                    title: 'World',
                    tree: {
                        tree_id: 0,
                        level: 0,
                    }
                }}
                activeRoute={activeRoute}
                activeRoutes={activeRoutes}
                routes={routes}
                user={user}
                match={match}
                // title={title}
                key={`children-choice`}
                className={classes.childSelect}
            />);

        }
    }
    if (target && target.ancestors && target.__type__ != 'Node' && target.__type__ != 'Group') {
        /* Nodes and groups are special because they are focus */
        target.ancestors.map((ancestor, i) => {
            if (ancestor.__url__) {
                ancestor_list.push(
                    <Link
                        key={`target-ancestor-link`}
                        to={ancestor.__url__}>{icon_for_type(ancestor.__type__) || null} {ancestor.title}
                    </Link>
                );
            } else {
                ancestor_list.push(<MuiTypography
                    key={`target-ancestor-title`}
                    component="h4"
                    variant="h4"
                    color="inherit"
                    noWrap
                    classes={{}}
                >
                    {ancestor.title}
                </MuiTypography>);
            }
        });
    }
    ancestor_list.push(<MuiTypography
        key='title'
        component="h5"
        variant="h5"
        color="inherit"
        noWrap
        classes={{}}
    >
        {title}
    </MuiTypography>);
    const identity_key = `breadcrumbs-${(focus && (focus.__key__ || `${focus.__type__}.${focus.__pk__}`))}-${activeRoute.path}`;
    const trail = <Breadcrumbs aria-label="Breadcrumb" className={classes.title} classes={{}} key={identity_key}>
        {ancestor_list}
    </Breadcrumbs>;

    return (
        <AppBar className={classNames(classes.appBar)} classes={{}}>
            <DocumentTitle title={title} />
            <Toolbar className={classes.toolbar} classes={{}}>
                <Link key="home-link" to={base_url}><img
                    src="/ptheme/img/company-logo.svg"
                    alt={(Config.company_short_name) + " Logo (click to go to dashboard)"}

                    className={classes.logo}
                    title="Go to Dashboard"
                    onClick={(evt) => {
                        PAGE_FOCUS.set_focus(null);
                        redirect('/m/', history);
                        evt.stopPropagation();
                    }}
                /></Link>
                {trail}
                <UserName />
            </Toolbar>
        </AppBar>
    );
};
AppHeader.displayName = 'AppHeader';


AppHeader.propTypes = {
    base_url: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(AppHeader));
