/* Provides Dashboard Layout for page *unless* it is suppressed */
import React from 'react';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppContent from './AppContent';
import with_error_boundary from 'errorboundary';

const CheckedAppHeader = with_error_boundary(AppHeader);
const CheckedAppSidebar = with_error_boundary(AppSidebar);
const CheckedAppContent = with_error_boundary(AppContent, 'appContent');

const DashLayout = (props) => {
    const { no_sidebar, routes, base_url, children } = props;
    return <React.Fragment>
        <CheckedAppHeader routes={routes} base_url={base_url} />
        {(!no_sidebar) && <CheckedAppSidebar routes={routes} />}
        <CheckedAppContent>
            {children}
        </CheckedAppContent>
    </React.Fragment>;
};

export default DashLayout;