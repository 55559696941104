import React from 'react';
import PropTypes from 'prop-types';

import { stores } from 'storeregistry';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import with_error_boundary from 'errorboundary';
import DashLayout from './dashlayout';
import ProtectedRoute from 'dash/protectedroute';
import LicensesPage from 'dash/licenses/LicensesPage';
import { with_bare_focus } from 'dash/focusprovider';
import wraps from 'wraps';
import { requiring_system_store } from 'systemstorage';
import { ActiveRouteContext } from 'dash/navigationUtils';
import ResolveTarget from './resolvetarget';
import { ResolveFocus } from './focusprovider';
import { LoginControl } from 'dash/login';


export const NoMatch = (props) => {
    return <div>404 not found on {props.history.location.pathname}</div>;
};

const CatchingRoute = with_error_boundary(Route);
CatchingRoute.displayName = 'CatchingRoute';

const historyListener = (routes) => {
    return (location) => {
        /* Listen to history and report the new address */
        console.log(`Location updated to ${location.pathname}`);
    };
};

const ProtectedRouteWithSystem = requiring_system_store(ProtectedRoute);

const ResolvingRoutes = (props) => {
    const { resolve = [] } = props;
    const r_resolve = resolve.slice(0).reverse();
    const activeRoute = React.useContext(ActiveRouteContext);
    let current = props.children;
    r_resolve.map((res, index) => {
        const { param, defaultParam, ...restProps } = res;
        let pk = activeRoute.match.params[param];
        if (pk != '__default__') {
            if (pk) {
                pk = parseInt(pk);
            }
        }
        if (pk === null || pk === undefined) {
            if (defaultParam !== undefined) {
                pk = defaultParam;
            }
        }
        if (pk !== null && pk !== undefined && pk !== 0) {
            if (restProps.as_focus) {
                current = <ResolveFocus key={`resolve-target-${index}-${res.__type__}-${pk}`} __pk__={pk} {...restProps}>{current}</ResolveFocus>;

            } else {
                current = <ResolveTarget key={`resolve-target-${index}-${res.__type__}-${pk}`} __pk__={pk} {...restProps}>{current}</ResolveTarget>;
            }
        }
    });
    return current;
};
ResolvingRoutes.displayName = 'ResolvingRoutes';

class AppRoutes extends React.Component {
    componentDidMount() {
        const { history } = this.props;
        history.listen(historyListener(this.props.routes));
    }
    renderRoute(item, parents) {
        const { routes, base_url, focus } = this.props;
        const render_children = (props) => {
            const itemProps = item.props || {};
            if ((!item.renderer) && (!item.component)) {
                throw new Error(`Item has no renderer or component defined ${JSON.stringify(item)}`);
            }
            const Renderer = item.renderer || wraps((props => {
                const Component = item.component;
                const params = (item.params && item.params(props)) || {};
                const base = <Component
                    key={item.path}
                    focus={focus}
                    {...props}
                    {...itemProps}
                    {...params}
                />;
                return base;
            }), item.component, 'AppRoutes.Route');
            const final_props = {
                focus: focus,
                ...props,
            };
            let base = <Renderer {...final_props} />;
            if (!item.public) {
                base = <ProtectedRouteWithSystem protection_test={(props) => {
                    const { user } = props;
                    // console.log(`Testing for protected route: ${stores.system_status &&
                    //     stores.system_status.config &&
                    //     stores.system_status.config.system &&
                    //     stores.system_status.config.system.eula_accepted}`);
                    return user.has_permission('factory') || (
                        stores.system_status &&
                        stores.system_status.config &&
                        stores.system_status.config.system &&
                        stores.system_status.config.system.eula_accepted
                    );
                }} protected={base} protection_control={LicensesPage} />;
                // base = <ProtectedRoute protection_test={(props) => {
                //     return props.user.name;
                // }} protected={base} />;
            }
            return base;
        };
        const dash_render = (props) =>
            <ActiveRouteContext.Provider
                value={{ match: props.match, activeRoute: item, activeRoutes: [...(parents || []), item] }}
            >
                <ProtectedRoute protection_test={(props) => (props.user.name || item.public)} protected={
                    <ResolvingRoutes resolve={item.resolve}>
                        <DashLayout routes={routes} base_url={base_url} no_sidebar={item.no_sidebar}>
                            {render_children(props)}
                        </DashLayout>
                    </ResolvingRoutes>
                } protection_control={(props) => {
                    return <DashLayout base_url={base_url} no_sidebar={true}><LoginControl onSave={props.onSave} /></DashLayout>;
                }} />
            </ActiveRouteContext.Provider >
            ;
        // TODO: add context such that it's trivial/easy to find current rendered route component
        return (
            <CatchingRoute
                exact={!!item.exact}
                key={item.path}
                end={item.end}
                strict={item.strict}
                path={item.path}
                render={dash_render}
            />
        );
    }

    renderRoutes(routes, parents = null) {
        return routes.reduce((allRoutes, page) => {
            const subRoutes = page.routes ?
                this.renderRoutes(page.routes, [
                    ...(parents || []),
                    page
                ]) : [];
            return [...allRoutes, ...subRoutes, this.renderRoute(page, parents)];
        }, []);
    }
    NoMatch = (props) => {
        const { routes, base_url } = this.props;
        return <ActiveRouteContext.Provider
            value={{ match: null, activeRoute: this.props.routes[0], activeRoutes: [] }}
        >
            <DashLayout routes={routes} base_url={base_url} no_sidebar={false}>
                <NoMatch {...props} />
            </DashLayout>
        </ActiveRouteContext.Provider>;
    }

    render() {
        return (
            <Switch>
                {this.renderRoutes(this.props.routes)}
                <CatchingRoute component={this.NoMatch} />
            </Switch>
        );
    }
}

AppRoutes.propTypes = {
    base_url: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
        routes: PropTypes.array,
    })).isRequired,
};

export default with_bare_focus(AppRoutes);
