import React from 'react';
import { GroupEditTable, group_specific_storage } from './groupedittable';
import { providing_nearest_form } from 'formprovider';
import { with_resolve_focus } from 'dash/resolvefocus';
import { ReactForm } from 'reactform';
import { with_focus_target } from 'dash/resolvefocus';
// import { withStyles } from '@material-ui/core/styles';
import EditingCard from 'dash/editingcard';
import NodeConfigTable from 'shogun/nodeconfigtable';
import DeployPlanWidget from './deployplanwidget';
import {
    construct_controls,
    default_cancel_button,
    default_save_button,
    form_action
} from 'reactform';
import FocusLink from 'dash/focuslink';
import Icon from '@material-ui/core/Icon';
import { useHistory } from 'react-router-dom';
import redirect from 'redirect';


const BaseDeployView = (props) => {
    /* Given a group, deploy a given service group onto it (or its children) */
    const { target } = props;
    const history = useHistory();

    return <React.Fragment>
        <EditingCard title={`${target.type_name} ${target.title} Apply`}>
            <ReactForm
                target={target}
                form_key={'Promote'}
                use_dialog={false}
                default_messages={{
                    'success': 'Cluster apply complete',
                    'errors': 'Cannot proceed with the application',
                }}
                buttons={[
                    (self) => default_save_button(self, {
                        'name': 'Apply Changes',
                        'action_message': 'Apply',
                        'busy_message': `Applying to ${target.title}`,
                        'title': `Apply our current changes to devices in ${target.title}`,
                    }),
                    (self) => default_cancel_button(self, {
                        'name': 'Cancel Apply',
                        'action_message': 'Cancel',
                        'title': `Cancel application of the changes`,
                        'onClick': (evt) => {
                            redirect(target.__url__, history);
                        }
                    }),
                ]}
            />
        </EditingCard>
        <EditingCard title="Applied Configurations" no_wrapper={true}>
            <NodeConfigTable node={target} />
        </EditingCard>
    </React.Fragment>;
};
const DeployView = providing_nearest_form(with_focus_target(BaseDeployView, true), 'shogunconf_forms');

export default DeployView;
export { DeployView, BaseDeployView };
