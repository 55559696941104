import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import classNames from 'classnames';
import { with_focus } from 'dash/focusprovider';
import { EditIntent } from 'editintent';
import { delete_target_action } from 'reactform/actionmenu';
import AlignDiv from 'reactform/aligndiv';
import theme from 'dash/theme';
import EditingCard from 'dash/editingcard';
import { providing_nearest_form } from 'formprovider';
import { with_resolve_focus, with_focus_target } from 'dash/resolvefocus';
import { WithToolbar } from 'shogun/shogunpromotebar';
import WithCurrentUser from 'withcurrentuser';
import Config from 'config';

class BaseServiceGroupsTable extends React.Component {
    column_callback = (columns, table) => {
        const { storage } = table.props;
        if (storage && storage.extra_fields) {
            return [
                ...columns.slice(0, columns.length - 1),
                ...(
                    storage.extra_fields.map(
                        (f, i) => table.field_to_column(
                            {
                                ...f,
                                display_widget: 'ZeroNullInteger',
                            }, columns.length - 1 + i,
                            0,
                            'ShogunServiceGroups'
                        )
                    )
                ),
                columns[columns.length - 1],
            ];
        }
        return columns;
    }
    render() {
        const { form_details, group } = this.props;
        if (!(form_details)) {
            return <MuiLoader title={`No form details yet`} />;
        }
        return <ReactFormTable
            wrap_data={(record, table) => {
                return [record,];
            }}
            link_to_edit={true}
            link_only={true}
            preference_key={`service-group-table`}
            form_details={form_details}
            overall_target={group}
            field_actions={{
                'name': (target, context, menu) => {
                    return [
                        group && {
                            /* Create a deletion action for the target 
                            
                            target -- record in the db to delete
                            context -- 
                            
                            */
                            label: `Remove ${target.title}`,
                            icon: theme.icons.remove,
                            list_item_props: {
                                title: `Remove ${target.title} from ${group.title}`,
                                className: classNames('delete-intent'),
                                color: 'secondary',
                                disabled: (!target) || (target && target.editable === undefined) || (!target.editable),
                            },
                            form_props: {
                                title: `Remove ${target.title} from ${group.title}`,
                                form_key: 'UndeployChoice',
                                target: group,
                                context: {
                                    'service_group': target.id,
                                },
                            },
                        },
                        delete_target_action(target, context, menu),
                    ];
                }
            }}
            extra_fields={{
                'ServiceGroup': [
                    {
                        'name': 'services',
                        'label_id': 'id_services',
                        'label': 'Services',
                        filterable: false,
                        'field': {
                            'help_text': 'Set of services within the service group'
                        }
                    },
                    {
                        'name': 'groups', 'label_id': 'id_groups', 'label': 'Groups',
                        // 'widget': (props) => {
                        //     const { record, field } = props;
                        //     const value = record.groups;

                        //     if (value && value.length) {
                        //         return <AlignDiv key='groups' align={props.alignment}>
                        //             {`${value.length}`}
                        //         </AlignDiv>;
                        //     } else {
                        //         return '';
                        //     }
                        // },

                        filterable: false,
                        'field': {
                            'help_text': 'Set of Groups to which this service is assigned',
                        },
                    },
                    {
                        'name': 'nodes', 'label_id': 'id_nodes', 'label': 'Affected Devices',
                        'accessor': 'nodes',
                        'field': {
                            help_text: 'Number of network nodes (devices) that are affected by the Config Package',
                            display_widget: 'ZeroNullInteger',
                        },
                    },
                    // {
                    //     'name':'services','label_id':'id_services','label':'Services',
                    //     'widget': (props) => {
                    //         const { record, field } = props;
                    //         const value = record.services;
                    //         if (value && value.length) {
                    //             return <AlignDiv key='services' align={props.alignment}>
                    //                 {`${value.length}`}
                    //             </AlignDiv>;
                    //         } else {
                    //             return '';
                    //         }
                    //     },
                    //     filterable: false,
                    //     'field': {
                    //         'help_text': 'Set of services to deploy across this group',
                    //     }
                    // },
                ]
            }}
            column_callback={this.column_callback}
            exclude={[
                'notes',
            ]}
            {...this.props}
        />;
    }
}

const service_groups_storage = (group, descendants = true) => {
    /* Get a group-specific storage for given group *or* nodes/non-nodes
    
    */
    let context = {};
    if (group && (group.id || group.__pk__)) {
        if (descendants && group.tree) {
            console.log(`Descendants of ${group.title} = ${group.tree.tree_id} ${group.tree.lft}:${group.tree.rght}`);
            context = {
                groups__tree_id: group.tree.tree_id,
                groups__lft__gte: group.tree.lft,
                groups__rght__lte: group.tree.rght,
            }
        } else {
            context = {
                groups__in: group.id || group.__pk__,
            }
        }
    }
    return {
        default_context: context,
        forms_key: 'shogunconf_forms',
        form_key: 'ShogunServiceGroups',
        editing_forms: ['ServiceGroup'],
        quick_settings: {
        },
    };
};


const ServiceGroupsTable = (props) => {
    return <WithTabularStorage
        key={`service-groups-for-group-${props.storage_props.default_context.group || 'all'}`}
        storage_props={props.storage_props}
        Component={with_focus(BaseServiceGroupsTable)}
        form_keys={['ServiceGroup']}
        service_group={props.storage_props.default_context.service_group}
        service_type={props.storage_props.default_context.service_type}
        {...props}
    />;
};

const GroupServiceGroupCard = (props) => {
    const { target, collapsing, user } = props;
    const servicegroup_storage = service_groups_storage(target);

    const servicegroup_actions = [
        user && user.has_permission('shogunconf.edit_content') && <EditIntent
            key="add-service-group"
            target={{
                'type': 'ServiceGroup',
                'id': null,
            }}
            context={{
                'group': target && target.id,
                'owner': target.owner,
            }}
            action={`Create in ${target.title}`}
            label={`New Config Package`}
            help_text={`Adds a new Config Package assigned to ${target.title}`}
        />,
        user && user.has_permission('shogunconf.edit_content') && <EditIntent
            key="add-existing-service-group"
            target={target}
            form_key='AddServiceGroup'
            context={{
                'group': target.id,
            }}
            action={`Add Existing Service(s) to ${target.title}`}
            label={`Add Config Package`}
            help_text={`Adds existing Config Packages to ${target.title}`}
        />,
        // <Link key='deploy-existing' to={`/m/deploy/${target.id}`}>Add Config Package</Link>,
    ].filter(x => !!x);
    return <EditingCard
        title={`${target.type_name} 
        ${target.title} Config Packages`}
        no_wrapper={true}
        collapsing={collapsing}
        actions={servicegroup_actions}
        key={`group-service-group-card-${(target && (target.id || target.__pk__)) || 'root'}`}
    >
        <ServiceGroupsTable
            storage_props={servicegroup_storage} group={target}
            preference_default={{
                hiddenColumns: [
                    ...(
                        Config.world_is_node ? ['owner'] : []
                    )
                ]
            }}
        />
    </EditingCard >;
};

const GroupServiceGroupsView = providing_nearest_form(
    WithToolbar(
        WithCurrentUser(
            with_focus_target(GroupServiceGroupCard, true)
        )
    ),
    'shogunconf_forms'
);


export default ServiceGroupsTable;
export { GroupServiceGroupCard, service_groups_storage, BaseServiceGroupsTable, ServiceGroupsTable, GroupServiceGroupsView };
