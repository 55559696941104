import React from 'react';

import MuiAppBar from 'dash/MuiAppBar';
import MuiTypography from 'dash/MuiTypography';
// Dash version we directly import the css...
// import "../../../../../atxstyle/atxstyle/static/css/font-awesome.min.css";
export * from './ReactFormSuccessOrError';
import "widgets";


const baseFormButtons = ['cancel', 'save'];

const BaseActionWrapper = (props) => {
    return <div
        style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between'
        }}
    >
        {props.controls}
    </div>;
};
const InPageActionWrapper = (props) => {
    return <div style={{ display: 'flex' }}>
        {props.controls}
    </div>;
};

const WrapFormActions = (controls, self) => {
    const { use_dialog } = self.props;
    if (use_dialog === false) {
        return <InPageActionWrapper controls={controls} />;
    }
    return <BaseActionWrapper
        controls={controls}
    />;
};

class BaseFormTitle extends React.Component {
    render() {
        const { action, target, describe, use_dialog, title } = this.props;
        const actionName = action || (target.id ? 'Edit' : 'Create');
        const final_title = title || `${actionName}: ${describe(target, this)}`;

        return final_title;
    }
}

export { WrapFormActions, baseFormButtons, BaseFormTitle };
