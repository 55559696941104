import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import RebootRequired from 'dash/RebootRequired';
// import SystemLockout from 'dash/SystemLockout';

const styles = theme => ({
    container: {
        flex: 1,
    },
    content: {
        marginLeft: theme.sidebarWidth,
        width: `calc(100% - ${theme.sidebarWidth}px)`,
        padding: theme.spacing(1)
    }
});

class AppContent extends React.Component {
    render() {
        const { classes, children } = this.props;

        return (
            <div className={classes.container}>
                <RebootRequired />
                {/* <SystemLockout /> */}
                <div className={classNames(classes.content)}>
                    {children}
                </div>
            </div>
        );
    }
}

AppContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppContent);
