/* React-level support for a filter context

Filter Contexts provide a space in which a given set of filters can be
modified by user changes to filter the queries that various views
will apply to their contents.

<FilterContext.Provider value={`string-key`}>
    ...
    () => {
        const [filters,set_item(key,value)] = useMetricFilters();
        ...
    }
    ...
</FilterContext.Provider/>

*/

import React from 'react';
import METRIC_FILTER_STORAGE from 'storages/metricfilterstorage';
import md5 from 'md5';

const _FilterContext = React.createContext(null);

const FilterContext = (props) => {
    const { filter_key, defaults, storage = METRIC_FILTER_STORAGE } = props;
    const values = storage.current(filter_key, defaults);
    const [hash, setHash] = React.useState(filterHash(values));
    const itemCallback = React.useCallback((update) => {
        if (update.metric == filter_key) {
            console.log(`Updating context`);
            setHash(filterHash(update.filters));
        }
    }, [filter_key]);
    React.useEffect(() => {
        console.log(`Registering for updates on ${filter_key}#${hash}`);
        storage.change.listen(itemCallback);
        return () => {
            console.log(`Deregistering for updates on ${filter_key}#${hash}`);
            storage.change.ignore(itemCallback);
        };
    }, [filter_key]);
    console.log(`Re-rendering context for ${filter_key}`);
    return <_FilterContext.Provider
        key={`${filter_key}`}
        value={{ filters: storage.current(filter_key), filter_key: filter_key, storage: storage, hash: hash, defaults: defaults }}
    >{props.children}</_FilterContext.Provider>;
};

const useMetricFilters = () => {
    /* Use metric filters for a given metric

    returns [{currentFilterSet}, set(key,value)] for the given metric
    */
    const { filters, filter_key, storage, hash } = React.useContext(_FilterContext);
    console.log(`Updating useMetricFilters call for ${filter_key}`);
    const set_item = React.useCallback((key, value) => {
        console.log(`Set filter key on ${filter_key}: ${key} => ${JSON.stringify(value)}`);
        storage.set(filter_key, key, value);
    }, [filter_key]);
    return [filters, set_item, hash];
    // const [mapping, setMapping] = React.useState(storage.current(metric));
    // const setCallback = React.useCallback((key, value) => storage.set(metric, key, value), [metric]);
    // return [mapping, setCallback];
};

const useFilterClearCallback = () => {
    /* Create a callback that clears metric settings to default */
    const { storage, filter_key, defaults } = React.useContext(_FilterContext);
    return React.useCallback(() => {
        console.log(`Setting metric key ${filter_key} to default settings`);
        storage.clear(filter_key, defaults);
    }, [filter_key]);
};

/* JSON dump is not predictable, so create a predictable dump,
from https://stackoverflow.com/questions/42491226/is-json-stringify-deterministic-in-v8
*/

const sortObj = (obj) => (
    obj === null || typeof obj !== 'object'
        ? obj
        : Array.isArray(obj)
            ? obj.map(sortObj)
            : Object.assign({},
                ...Object.entries(obj)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([k, v]) => ({ [k]: sortObj(v) }),
                ))
);

const sorted_dump = (value) => {
    const typ = typeof value;
    if (typ == 'string' || typ == 'number' || value === null || typ == 'undefined' || typ == 'boolean') {
        return JSON.stringify(value);
    } else if (Array.isArray(value)) {
        return `[${value.filter(v => v !== undefined).map(v => sorted_dump(value)).join(',')}]`;
    } else {
        const keys = Object.keys(value);
        keys.sort();
        const segments = keys.map(k => {
            if (value[k] !== undefined) {
                return `${JSON.stringify(k)}:${sorted_dump(value[k])}`;
            }
        });
        return `{${segments.join(',')}}`;
    }
};


const filterHash = (mapping) => {
    /* Construct a hash suitable for setting a unique key describing the filter-set */
    const raw = sorted_dump(mapping);
    const content = md5(raw);
    console.log(`New hash ${content}`);
    return content;
};

export default useMetricFilters;
export { useMetricFilters, filterHash, FilterContext, useFilterClearCallback };