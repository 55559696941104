/* Data processing operations for pulling out graph series */
import { parallel_sets, apply_accessor } from './accessor';
import order_by_keys from './orderbykeys';
import COLORS from './colors';
import PropTypes from 'prop-types';
import { build_color_map, query_color_map } from './colormap';

const limit_range = (value, min, max) => {
    /* Clamp value within range */
    if (value < min) {
        return min;
    }
    if (value > max) {
        return max;
    }
    return value;
};


function filter_exclude(records, excluder) {
    /* Filter accepted/unaccepted for records */
    if (!excluder) {
        return [records, []];
    }
    const result = [[], []];
    records.map((record) => {
        if (excluder(record)) {
            result[1].push(record);
        } else {
            result[0].push(record);
        }
    });
    return result;
}
function first_accessible(records, accessor) {
    /* Return the first result that is non-null applying accessor to records */
    for (let i = 0; i < records.length; i++) {
        let value = apply_accessor(records[i], accessor);
        if (value) {
            return value;
        }
    }
    return null;
}

function sort_by_accessors(accessors) {
    /* Creates a function that will apply accessors to records and compare the results 
    
    accessors -- array of data-accessors to use to create sorting key function,
                 note that the accessors will potentially be applied thousands
                 or hundreds of thousands of times...
    */
    if (!Array.isArray(accessors)) {
        accessors = [accessors];
    }
    const compare = (first, second) => {
        for (let i = 0; i < accessors.length; i++) {
            const first_value = apply_accessor(first, accessors[i]);
            const second_value = apply_accessor(second, accessors[i]);
            if (first_value === second_value) {
                continue;
            } else if (first_value === undefined || first_value === null) {
                if (second_value === undefined || second_value === null) {
                    continue;
                } else {
                    return -1;
                }
            } else if (second_value === undefined || second_value === null) {
                return 1;
            } else {
                if (first_value > second_value) {
                    return 1;
                } else if (second_value > first_value) {
                    return -1;
                } else {
                    return 0;
                }
            }
        }
        return 0;
    };
    return compare;
}
function format_accessors(record, accessors) {
    /* Format a template of [literal,accessor,literal,accessor,...] into a simple string for record */
    let result = [];

    for (let i = 0; i < accessors.length; i++) {
        let value = null;
        if (i % 2) {
            let [accessor, functor] = accessors[i].split('|', 2);
            value = apply_accessor(record, accessors[i]);
            if (functor && functor.length) {
                let [operator, args] = functor.split(':', 2);
                if (operator == 'default') {
                    if (value === undefined || value === null) {
                        value = args;
                    }
                } else {
                    console.log(`Do not have a template functor: ${operator}`);
                }
                value = `${value}`;
            } else if (value === null || value === undefined) {
                value = '';
            } else {
                value = `${value}`;
            }
        } else {
            value = accessors[i];
        }
        result.push(value);
    }
    return result.join('');
}

const grouping_function = (accessors) => {
    /* Create a function producing keys by which records should be grouped 
    
    accessors -- array of accessors to apply to the record, they will be 
                 formatted into a string with ':' between them using
                 format_accessors()
    
    returns function(record) => 'string'
    */
    const pattern = [];
    if (typeof accessors == 'string') {
        accessors = [accessors];
    }
    for (let i = 0; i < accessors.length; i++) {
        if (i == 0) {
            pattern.push('');
        } else {
            pattern.push(':');
        }
        pattern.push(accessors[i]);
    }
    const group_key = (record) => {
        const key = format_accessors(record, pattern);
        return key;
    };
    return group_key;
};


function group_by_accessor(records, metric, accessor, label_accessor = null) {
    /* Group records in series by metric.accessor */
    if (records && !Array.isArray(records)) {
        records = Object.keys(records).map(x => records[x]);
    }
    if ((!records) || (!records.map)) {
        return [];
    }
    const groups = {};
    // eslint-disable-next-line no-inner-declarations
    function add_to_group(__key__, record) {
        if (!groups[__key__]) {
            groups[__key__] = [];
        }
        groups[`${__key__}`].push(record);
    }
    records.map(rec => {
        var __key__ = apply_accessor(rec, accessor);
        if (__key__ === undefined) {
            __key__ = null;
        }
        add_to_group(__key__, rec);
    });
    const color_map = build_color_map(metric, {}, {});
    return Object.keys(groups).map((__key__) => {
        let label = (metric.labels && metric.labels[__key__]) || __key__;
        const value = groups[__key__];
        if (label_accessor && value && value.length) {
            /* If you have defined it, try to use the label accessor to get the group's label */
            label = first_accessible(value, label_accessor) || label;
        }
        const record = {
            '__key__': __key__,
            'label': label,
            'value': value,
            'grouped_by': __key__,
            'data': groups[__key__],
        };
        const color = (__key__ === '__excluded__') ?
            COLORS.DISABLED :
            query_color_map(__key__, color_map);
        // ((metric.colors && metric.colors[__key__]) || undefined);
        if (color !== undefined && color !== null) {
            record.color = color;

        }
        return record;
    });
}
function enumeration_order(series, metric) {
    if (metric.order) {
        // console.info(`Applying order to ${metric.__key__}`);
        return order_by_keys(metric.order, series, '__key__');
    }
    return series;
}
function aggregate_series(series, format, metric) {
    if (metric.aggregate) {
        return series.map((record) => {
            record.value = apply_accessor(record, metric.aggregate);
            return record;
        });
    } else if (format.aggregate) {
        /* Note the different protocol here */
        return series.map((record) => {
            return format.aggregate(record);
        });
    }
    return series;
}

function extract_series_grouped(__key__, partial, metric, format, group_by, group_by_label = null) {
    let [records, excluded] = filter_exclude(partial, metric.exclude);
    console.log(`${records && records.length} records to group by ${group_by}`);
    const series = group_by_accessor(records, metric, group_by, group_by_label);
    if (!('ignore_excluded' in metric) && excluded.length) {
        const label = (metric.labels && metric.labels['__excluded__']) || 'Excluded';
        series.push({
            '__key__': __key__ ? `${__key__}.__excluded__` : '__excluded__',
            'label': label,
            'value': excluded,
            // 'color': COLORS.DISABLED,
        });
    }
    // console.log(`${series.length} groups created`);
    const ordered = enumeration_order(series, metric);
    ordered.map((record, index) => {
        if (record.coordinate === undefined) {
            record.coordinate = index;
        }
        if (record.id === undefined) {
            record.id = index;
        }
    });
    return aggregate_series(ordered, format, metric);
}
const coordinate_extract = (record, metric, index) => {
    if (typeof metric.coordinate === 'string') {
        return apply_accessor(record, metric.coordinate);
    } else if (typeof metric.coordinate === 'function') {
        return metric.coordinate(record);
    } else {
        return index;
    }
};
function extract_series_ungrouped(__key__, partial, metric, format, label_accessor = null) {
    if (partial === null) {
        return null;
    } else if (!partial.map) {
        console.info(`Non array in extract series ungrouped ${JSON.stringify(metric)}: ${partial}`);
        return null;
    }
    return partial.map((record, index) => {
        if (metric.exclude && metric.exclude(record)) {
            return;
        }
        const value = apply_accessor(record, metric.accessor);
        let value_label = value;
        if (metric.graph_label_y_function) {
            value_label = metric.graph_label_y_function(value, metric);
        }
        let label = label_accessor && `${apply_accessor(record, label_accessor) || '?'}: ${value_label}`;
        if (!label) {
            label = metric.label ? metric.label(record) : index;
        }
        const url = metric.link ? metric.link(record) : null;
        return {
            '__key__': __key__,
            'id': (metric.id && metric.id(record)) || index,
            'label': label,
            'value': value,
            'coordinate': coordinate_extract(record, metric, index),
            'url': url,
            'data': record,
        };
    });
}

function metric_dataset(dataset, metric) {
    /* Get the overall dataset related to the metric */
    const parallel = parallel_sets(
        dataset,
        metric.dataset_accessor || metric.__key__.split('.')[0],
    );
    // console.info(`Parallel sets: ${JSON.stringify(Object.entries(parallel))} extracted for ${metric.__key__}`);
    return parallel;
}

const split_streams_by = (split_by, split_by_label, parallel, graph, metric, format) => {
    const split = {};
    Object.entries(parallel).map((r, index) => {
        let [__key__, partial] = r;
        if (partial) {
            partial.map(r => {
                const v = apply_accessor(r, split_by, __key__);
                if (split[v] === undefined) {
                    let label = apply_accessor(r, split_by_label || split_by);
                    split[v] = [];
                }
                split[v].push(r);
            });
        }
    });
    return split;
};

function extract_data(graph, dataset, metric, format) {
    /* Create graph-compatible dataset

    returns [
        // Individual data-sets from accessor wild-cards
        [
            {__key__:string,value:any,label:string,id:any},
            ...
        ],
    ];

    */
    // if ((!dataset) || (!dataset.map)) {
    //     console.info("Null dataset passed to extract_data");
    //     return [];
    // }
    let parallel = metric_dataset(dataset, metric);
    if (graph.split_by) {
        parallel = split_streams_by(graph.split_by, graph.split_by_label, parallel, graph, metric, format);
    }
    let group_by = graph.group_by;
    if (!group_by) {
        if (format.group_by) {
            group_by = metric.group_by || metric.accessor;
        }
    }
    let label_accessor = graph.label_accessor || metric.label_accessor;
    // console.log(`Group by ${group_by}: ${graph.group_by} ${format.group_by} ${metric.group_by}`);
    const combined_metric = {
        ...graph,
        ...metric,
    };

    const series = Object.entries(parallel).map((r, index) => {
        let [__key__, partial] = r;
        if (group_by) {
            // console.info("Grouped series");
            return extract_series_grouped(__key__, partial, combined_metric, format, group_by, graph.group_by_label);
        } else {
            /* Un-grouped data, so the result will be one record per source record */
            // console.debug("Un-grouped series");
            return extract_series_ungrouped(__key__, partial, combined_metric, format, label_accessor);
        }
    });
    return series;
}
const AccessorType = PropTypes.oneOfType([PropTypes.string, PropTypes.func]);

const MetricType = PropTypes.shape({
    dataset_accessor: AccessorType,
    __key__: PropTypes.string.isRequired,
});
const FormatType = PropTypes.shape({
    group_by: PropTypes.func,
    aggregate: PropTypes.func,
});
metric_dataset.propTypes = {
    'dataset': PropTypes.array.isRequired,
    'metric': MetricType.isRequired,
};
extract_data.propTypes = {
    'metric': MetricType.isRequired,
    'dataset': PropTypes.array.isRequired,
    'format': FormatType.isRequired,
};

const get_graph_data = (series) => {
    /* Given storage or array, find the graph data to display */
    let graph_data = series;
    if (series === null || series === undefined) {
        return null;
    } else if (!Array.isArray(series)) {
        if (series.type_key) {
            graph_data = series[series.type_key];
        } else {
            // Storage that has not yet loaded...
            return null;
        }
    }
    if (graph_data === null || graph_data === undefined) {
        return null;
    }
    return graph_data;
};

/* More modern API with accessors throughout */
const extract_dataset = (recordset, extraction, stacking, binning, sorting) => {
    recordset = get_graph_data(recordset);
    if (!recordset) {
        return [];
    }
    if (stacking) {
        /* If stacking is true, we'll return parallel datasets */
        let stacks = [];
        recordset.map(record => {
            let seriesset = apply_accessor(record, stacking);
            seriesset.map((series, index) => {
                series.index = index;
                if (stacks[index] === undefined) {
                    stacks[index] = series;
                } else {
                    stacks[index] = [...stacks[index], ...series];
                }
            });
        });
        return stacks.map(stack => extract_dataset(stack, extraction, null, binning, sorting));
    }
    if (binning) {
        recordset = bin_dataset(recordset, binning);
    }
    if (extraction) {
        recordset = recordset.map(record => extract_record(record, extraction));
    }
    if (sorting) {
        recordset.sort((a, b) => {
            const first = apply_accessor(a, sorting);
            const second = apply_accessor(b, sorting);
            return first < second;
        });
    }
    return recordset;
};

const extract_record = (record, extraction) => {
    /* Apply extraction rule to single record to produce new record */
    const result = { data: record };
    extraction.map(extract => {
        const value = apply_accessor(record, extract.accessor);
        const label = extract.label ? apply_accessor(record, extract.label) : `${value}`;
        result[`${extract.field}`] = value;
        result[`${extract.field}_label`] = label;
        result[`${extract.field}_type`] = extract.__type__;
        result[`${extract.field}_accessor`] = extract;
    });
    return result;
};

const bin_dataset = (recordset, binning) => {
    /* Process extracted data to bin the results into aggregated sets */
    let current_records = recordset;
    if (!Array.isArray(binning)) {
        /* For cases where a single binning is specified as e.g. just 'fieldname' */
        binning = [binning];
    }
    binning.map(binner => {
        const bins = {};
        recordset.map(record => {
            const bin_key = apply_accessor(record, binner.key);
            if (bin_key !== undefined) {
                let bin = bins[bin_key];
                if (bin === undefined) {
                    let label = binner.label ? apply_accessor(record, binner.label) : null;
                    if ((!label) && binner.labels) {
                        label = binner.labels[bin_key];
                    }
                    bin = bins[bin_key] = {
                        __key__: bin_key,
                        label: label,
                        records: [record],
                    };
                } else {
                    bin.records.push(record);
                }
            }
        });
        current_records = Object.values(bins);
        if (binner.aggregate) {
            current_records.map(record => {
                record[binner.aggregate.field || 'value'] = apply_accessor(record, binner.aggregate);
                return record;
            });
        }
        if (binner.sort) {
            current_records.sort((a, b) => {
                const first = apply_accessor(a, binner.sort);
                const second = apply_accessor(b, binner.sort);
                return first < second;
            });
        }
    });
    return current_records;
};
const dataset_min = (dataset, key) => {
    return Math.min(...dataset.map(record => record[key]));
};
const dataset_max = (dataset, key) => {
    return Math.max(...dataset.map(record => record[key]));
};
const parse_threshold = (field_name) => {
    const fragments = field_name.split('__').slice(1,).map(x => parseFloat(x));
    fragments.push(null);
    fragments.splice(0, 0, null);
    return fragments;
};

const threshold_convert = (threshold, field, record) => {
    const value = record[field];
    const base_field = field.split('__')[0];
    if (value) {
        const stack = [];
        const parsed = parse_threshold(field);
        return value.map((count, index) => {
            if (count == 0 && (threshold.show_zero !== undefined && !(threshold.show_zero))) {
                return null;
            }
            const start = parsed[index], stop = parsed[index + 1];
            let grouped_by = start;
            let comparison = "gte";
            if (start === null) {
                grouped_by = stop;
                comparison = "lte";
            }
            return {
                'grouped_by': grouped_by,
                'filter_key': `${base_field}__${comparison}`,
                'time': record.time,
                'value': count,
                'label': threshold.labels ? threshold.labels[index] : `${count}`,
                'color': threshold.colors ? threshold.colors[index] : null,
            };
        }).filter(x => x);
    } else {
        return null;
    }

};

const threshold_stacking = (graph) => {
    /* Create a function to stack the dataset by threshold fields */
    if (graph.thresholds) {
        const stacker = (record, extraction, stacking, binning, sorting) => {
            const stacks = [];
            Object.entries(graph.thresholds).map((threshold_rec, t_index) => {
                let [threshold_key, threshold] = threshold_rec;
                let result = threshold_convert(threshold, threshold_key, record);
                if (result) {
                    stacks.push(result);
                }
            });
            return stacks;
        };
        return stacker;
    } else {
        return undefined;
    }
};

export default extract_data;
export {
    extract_data,
    extract_series_grouped,
    extract_series_ungrouped,
    filter_exclude,
    get_graph_data,
    group_by_accessor,
    sort_by_accessors,
    format_accessors,
    metric_dataset,
    enumeration_order,
    grouping_function,
    split_streams_by,
    limit_range,

    extract_dataset,
    extract_record,
    bin_dataset,
    dataset_min,
    dataset_max,
    threshold_stacking,

};
