import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Notes from '@material-ui/icons/Notes';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ReactDialog from 'reactdialog';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        textAlign: 'center',
    },
    notes: {
        whiteSpace: 'pre-wrap',
        maxHeight: '50vh',
        maxWidth: '75vw',
    },

}))

const PopUpTextRender = (props) => {
    const { value, record, field, alignment } = props;
    const classes = useStyles()
    const [showing, setShowing] = React.useState(false);
    if (!value) {
        return null;
    }
    let dialog = null;
    if (showing) {
        const title = record.title || record.name || field.field.help_text;
        dialog = <ReactDialog
            title={title}
            onClose={() => setShowing(false)}
        >
            <pre className={classes.notes}>{value}</pre>
        </ReactDialog>;
    }
    const display = <div className={classes.wrapper}><IconButton title={'View content in a pop-up dialog'} aria-label='View content in a pop-up dialog' onClick={evt => {
        evt.stopPropagation()
        evt.preventDefault()
        setShowing(true);
    }}><Notes /></IconButton>{dialog}</div>;
    return display;

}

export default PopUpTextRender;