/* widgets for viewing EncodingProfile values */
import $ from 'fakequery';
import React from 'react';
import './loading.css';
import { shallow_compare } from 'signals';

class LoadingOverlay extends React.Component {
    static displayName = 'LoadingOverlay';

    static defaultProps = {
        'signal': null,
        'debug': false
    };
    state = {
        'error': null,
        'getting': null,
    };

    onUpdate = (backing) => {
        if (this.props.debug) {
            console.info(`Update on loading: err=${backing.error} get=${backing.getting}`);
        }
        const current = {
            error: this.state.error,
            getting: this.state.getting,
            ts: this.state.ts,
        };
        const update = {
            'error': backing.last_error || backing.error,
            'getting': backing.getting,
            'ts': backing.ts,
        };
        if (!shallow_compare(current, update)) {
            this.setState(update);
        }
    }

    componentDidMount() {
        console.debug(`Listening for changes on signal`);
        this.props.signal.listen(this.onUpdate);
    }
    componentWillUnmount() {
        this.props.signal.ignore(this.onUpdate);
    }

    render() {
        var className = 'loading-display';
        if (this.state) {
            if (this.state.getting) {
                className += ' loading';
            } else if (this.state.error) {
                className += ' error';
            } else {
                className += ' hidden';
            }
        }
        let date_display = null;
        if (this.state.ts) {
            const date = new Date(this.state.ts * 1000);
            date_display = <div className='date-overlay'>{`${date}`}</div>;
        }

        return (
            <div className={className}>
                {this.state.getting && <i className="fa fa-spinner spinner" title="Refresh from server in process" />}
                {this.state.error && <ul
                    className="error-list compact-list no-bullets"
                    title="Error report from server refresh">
                    {$.map((this.state.error.messages || this.state.error), function (msg, i) {
                        return (
                            <li key={`err-${i}`}>
                                {msg}
                            </li>
                        );
                    })}
                </ul>}
                {this.state.error && date_display}
            </div>
        );
    }
}

var LoadingOverlayFactory = React.createFactory(LoadingOverlay);

class LoadingDisplay extends React.Component {
    /* Displays a loading overlay on children (icon in the left corner) 
    
    Note: the overlay is refreshed on loading, but the *children*
    are not:

    import {LoadingDisplay} from 'reactloading';
    <LoadingDisplay signal={storage.loading}>
        <div>Hello world</div>
    </LoadingDisplay>
    
    */
    static defaultProps = {
        signal: null,
        debug: false
    };

    render() {
        return (
            <div className="loading-display-holder">
                <LoadingOverlay
                    signal={this.props.signal}
                    debug={this.props.debug}
                />
                <div className="loading-display-children">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

var LoadingDisplayFactory = React.createFactory(LoadingDisplay);

export {
    LoadingDisplayFactory,
    LoadingDisplay,
    LoadingOverlay,
    LoadingOverlayFactory
};

export default LoadingDisplay;