import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    shortened: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
});



const EllipsisText = (props) => {
    const { value, maxWidth = 10 } = props;
    const classes = useStyles();
    return <div className={classes.shortened} style={{ maxWidth: `${maxWidth}rem` }} title={value}>{value}</div>
};

export default EllipsisText;