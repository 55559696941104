import React from 'react';

class FontAwesomeIcon extends React.Component {
    static defaultProps = {
        'name': '',
        'title': null
    };
    render() {
        return <i className={'fa fa-'+this.props.name} title={this.props.title} />;
    }
}
var FontAwesomeIconFactory = React.createFactory( FontAwesomeIcon );

var fa_icon = function( name, title ) {
    /* Font-Awesome Icon by name, with optional title */
    return <FontAwesomeIcon name={name} title={title} />
};

var icon_column = function( base, icon_l, icon_r, params ) {
    /* Add columns to left and/or right of base in which icons may be displayed */
    params = params || {};
    var className = (params.className || '') + ' icon-column '+ ( icon_l? 'icon-offset-left':'') + (icon_r? ' icon-offset-right': '');
    icon_l = icon_l ? <span className="icon-left inline-block">{icon_l}</span> : null;
    icon_r = icon_r ? <span className="icon-right inline-block">{icon_r}</span> : null;
    return <div {...params} className={className}>
        {icon_l} {icon_r}
        <div className='icon-column-body'>{base}</div>
        <div className='clear-both' />
    </div>;
};

export {
    icon_column,
    fa_icon,
    FontAwesomeIconFactory,
    FontAwesomeIcon
};
