/* Formats timeline ticks with leading date, then 24hr, with seconds only if very scaled 

Any tick that is at 00:00:00 will be `3 Apr` style,
Then `... 3 Apr` style if first tick
Then `HH:MM:SS` iff 15s is more than 30px in current scale
Then `HH:MM` otherwise
*/

const DATE_FORMATTER = new Intl.DateTimeFormat('default', {
    'month': 'short',
    'day': 'numeric'
});
const TIME_FORMATTER = new Intl.DateTimeFormat('default', {
    'hour': '2-digit',
    'hour12': false,
    'minute': 'numeric',
});
const TIME_SECONDS_FORMATTER = new Intl.DateTimeFormat('default', {
    'hour': '2-digit',
    'hour12': false,
    'minute': 'numeric',
    'second': 'numeric',
});
const format_date_ticks = (value, index, scale, tickTotal) => {
    let d = new Date(value);
    let fut = new Date(d.getTime() + 15000);
    let show_seconds = (scale(fut) - scale(value) > 30);
    const is_midnight = (
        d.getHours() == 0
        && d.getMinutes() == 0
    );
    if (is_midnight) {
        return DATE_FORMATTER.format(d);
    } else if (index == 0) {
        return `...${DATE_FORMATTER.format(d)}`;
    } else if (show_seconds) {
        return TIME_SECONDS_FORMATTER.format(d);
    }
    return TIME_FORMATTER.format(d);
};

export default format_date_ticks;