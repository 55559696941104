/* widgets for viewing EncodingProfile values */
import $ from 'fakequery';
import { stores } from "storeregistry";
import { shallow_compare } from 'signals';
import { Signal } from 'signals';
// import {fa_icon} from 'faicon';
import { error_report } from 'debugfail';
import { ErrorList } from 'errorlist';
import { ReactDialog } from "reactdialog";
import { using_nearest_form } from 'formprovider';
import { LoadingDisplay } from 'reactloading';
import React from 'react';

import ReactFormButton from 'widgets/ReactFormButton';
import ReactFormField from './reactformfield';
import getIcon from 'getIcon';
// NB: there will be misc. objects and functions that need to change based on
// MUI vs R.
import { WrapFormActions, baseFormButtons, BaseFormTitle } from 'reactFormOverrides';
import field_comparison from './fieldcomparison';
import ReactFormSuccessOrError from 'ReactFormSuccessOrError';
import ServerLoaded from './serverloaded';
import ServerTemplated from './servertemplated';
import MultiTemplated from './multitemplated';
import formFromTarget from './formfromtarget';
import { with_bare_focus } from 'dash/focusprovider';
import {
    construct_controls,
    default_cancel_button,
    default_save_button,
    form_action
} from './constructcontrols';
import { store_by_key } from 'formprovider';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import redirect from 'redirect';

const styles = theme => ({
    fieldSetLabel: {
        fontSize: '1.5rem',
        color: theme.palette.primary.shading,
        marginTop: 0,
        marginBottom: '.25rem',
        borderTop: `4px solid ${theme.palette.primary.hint}`,

    },
    fieldSet: {
        marginBlockStart: '.25rem',
        marginBlockEnd: '.25rem',
    },
    fieldSetBody: {
        display: 'grid',
        columnGap: '2rem',
        rowGap: '.1rem',
    },
    fieldSetFlow1: {
        gridTemplateColumns: '1fr',
    },
    fieldSetFlow2: {
        gridTemplateColumns: '1fr 1fr',
    },
    fieldSetFlow3: {
        gridTemplateColumns: '1fr 1fr 1fr',
    },
    fieldSetFlow4: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
});

/** Base Form from which all ReactForm classes are derived

Properties:

    @param {object} form_details -- form description as from `reactforms.formjson._FormJSON`
    @param {object} context -- base/initial form-post values (e.g. to specify the parent instance)

    @param {string} form_key -- override the target's type to select a custom form
    @param {object} type_name -- override the name to use for the type
    @param {bool} editable -- if false, then by default use display widgets for components...

    @param {bool} show_label -- passed to ReactFormField.show_label
    @param {bool} speculative_modification -- if True, immediately update target
            model without waiting for server
    @param {object} widgets -- override the rendering widgets for {name: widget} fields,
            can be a string to index into the standard widget classes, or an actual Widget
            instance.
    @param {function} onSave -- callback to call when the form is saved
    @param {function} onClose -- callback to call when the form is closed
    @param {object} storage -- form storage to which we are bound, default `stores.forms`

    @param {array} buttons -- strings/button instances to populate actions
    @param {function} form_actions -- override default action rendering to produce
            buttons directly, see `construct_controls`
    @param {object} default_messages -- messages to render in snack bar (dash-only) when there
            is a failure, keys 'errors': string and 'success': string
*/
class BaseForm extends React.Component {
    state = {
        saving: null,
        validating: null,

        initial_post: null,
        initial_posted: false,
        initial_post_response: null,

        form_details: null,
        form_post: {},
        show_success_or_error_msg: false,
    };

    constructor(props) {
        super(props);
        this.reference_map = {};
    }
    safeSetState = (state) => {
        if (this.mounted) {
            return this.setState(state);
        }
        return null;
    };
    componentWillUnmount() {
        this.mounted = false;
    }
    componentDidMount() {
        this.mounted = true;
        this.do_initial();
    }


    field_reference = (field) => {
        var name = field.name || field;
        var current = this.reference_map[name];
        if (current === undefined) {
            current = this.reference_map[name] = React.createRef();
        }
        return current;
    };
    current_widget = (field) => {
        var reference = this.field_reference(field);
        return reference.current;
    };
    getFormKey = () => {
        if (this.props.form_key) {
            return this.props.form_key;
        }
        return this.props.target.__type__ || this.props.target.type;
    };
    static defaultProps = {
        'key': null,
        'form_details': null,
        'debug': false,
        'target': null,
        'context': null,
        'type_name': null,
        'show_label': true,
        'speculative_modification': false,
        'widgets': null,
        'editable': true,
        'onSave': null,
        'onClose': null,
        'onChange': null,
        'onEnter': null,
        'submitOnEnter': null,
        'autoFocus': null,
        'use_dialog': true,
        'buttons': baseFormButtons,
        'storage': stores.forms,
        'form_key': null,
        'handleSave': null, // (form,parameters) => promise
        'default_messages': {
            'errors': 'Could not save. See messages above.',
            'success': 'Record has been updated',
        },
        'form_actions': (self) => {
            const form_details = self.form_details();
            let buttons = self.props.use_dialog ? self.props.buttons : self.props.buttons.filter(x => x !== 'cancel');
            if (form_details && form_details.actions) {
                buttons = [
                    ...buttons,
                    ...form_details.actions,
                ];
            }
            return construct_controls(self, buttons);
        },
        'describe': (target, form) => {
            /* function to describe the target */
            if (target.title) {
                return target.title;
            }
            if (target.name) {
                return target.name;
            }
            if (target.description) {
                return target.description;
            }
            // TODO: form can't ever be evaluated
            if (form.props && form.props.type_name) {
                return form.props.type_name;
            }
            if (target.type_name) {
                return target.type_name;
            }
            return target.type_name || target.__type__ || target.type;
        }
    };
    fieldChanged = (field, new_value) => {
        var self = this;
        var new_post = {
            ...self.state.form_post,
        };
        // console.log(`${field.name} new value ${new_value}`);
        new_post[field.name || field] = new_value;
        /* ICK! due to setState not updating until we're finished, any change
        that requires more than a single update will "forget" the previous update
        unless we mutate our state here... yuck */

        if (self.props.beforeChange) {
            new_post = self.props.beforeChange(this, field, new_value, new_post);
            if (new_post === null || new_post === undefined) {
                console.warning(`beforeChange rejected update on ${field.name}`);
                return;
            }
        }

        new_post[field.name || field] = new_value;
        if (self.props.speculative_modification) {
            self.props.target[field.name] = new_value;
        }
        self.safeSetState({ 'form_post': new_post });
        if (self.state && self.state.changed_signal) {
            self.state.changed_signal.send({
                field: field,
                value: new_value
            });
        }
        if (self.props.onChange) {
            self.props.onChange(self, field, new_value);
        }
    };
    shouldShow = (field, current_values) => {
        /* should we currently show this field? */
        if (field.errors && field.errors.length) {
            /* per-field errors on a hidden field means it needs to be visible */
            return true;
        }
        if (field.hidden) {
            return false;
        }
        if (field.dependencies) {
            //console.log("Dependencies: "+JSON.stringify( field.dependencies ));
            if (!current_values) {
                current_values = this.jsonFormValues();
            }
            if (!dependencies_true(field, current_values)) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    };
    renderField = (field, current_values) => {
        var self = this;
        if (!field.hidden) {
            var should_show = self.shouldShow(field, current_values);
            // console.log(`Should show ${field.name}? ${should_show}`);
            field.key = field.name;
            return <ReactFormField
                should_show={should_show}
                autoFocus={this.props.autoFocus == field.name}
                editable={this.props.editable}
                show_label={self.props.show_label}
                field={field}
                key={field.name}
                ref={this.field_reference(field)}
                widgets={self.props.widgets}
                form={self}
                changed={self.props.target && self.props.target.changed}
                onChange={(new_value) => {
                    self.fieldChanged(field, new_value);
                }}
                onKeyDown={this.key_handler()}
            />;
        }
        return null;
    };
    jsonFormValues = () => {
        var self = this;
        var base = {};
        $.map(self.form_details().fields, (field) => {
            base[field.name] = field.value;
        });
        $.extend(base, self.props.context || {});
        $.extend(base, self.state.form_post);
        return base;
    };
    currentValue = (name) => {
        /* get current value as propagated to the form */
        var value = this.get_field(name).value;
        if (this.state.form_post[name] !== undefined) {
            value = this.state.form_post[name];
        }
        return value;
    };
    currentFormValues = () => {
        var self = this;
        var base = self.jsonFormValues();
        $.map(self.form_details().fields, (field) => {
            if (field.field.widget == 'CheckboxInput') {
                if (base[field.name]) {
                    base[field.name] = 'ON';
                } else {
                    delete base[field.name];
                }
            } else if (field.field.widget == 'SelectMultiple') {
                // Collapse array of objects used for multiple select widget
                // down to an array of the ID's
                base[field.name] = base[field.name].map(item => {
                    if (typeof item == 'string') {
                        if (item.startsWith('[') && item.endsWith(']')) {
                            item = JSON.parse(item);
                        }
                    }
                    if (typeof (item) === 'object') {
                        return item.id;
                    } else {
                        return item;
                    };
                });
            } else if (base[field.name] === null || base[field.name] === undefined) {
                delete base[field.name];
            } else {
                const value = base[field.name];
                if (value instanceof File || value instanceof Blob) {
                    base[field.name] = value;
                } else if (Array.isArray(value)) {
                    base[field.name] = JSON.stringify(base[field.name]);
                } else if (typeof value == 'object' && value !== null) {
                    base[field.name] = JSON.stringify(base[field.name]);
                }
            }
        });
        return base;
    };
    handleExternalChange = (record) => {
        /* when something else changes the value, update our fields *if* we haven't changed */
        console.debug(`External change to record ${record.key}`);
        var self = this;
        $.extend(self.props.target, record);
        $.map(self.form_details().fields, (field) => {
            var control = self.current_widget(field);
            if (control) {
                control.set_value(record[field.name], true);
            }
        });
    };
    handleKeyDown = (evt) => {
        /* handle bubbled key-down events */
        if (evt.keyCode === 27) {
            this.handleClose();
            evt.stopPropagation();
            return false;
        }
        return true;
    };
    key_handler = () => {
        /* return a key-up handler if we need it */
        if (this.props.onEnter || this.props.submitOnEnter) {
            return (evt) => this.handleEnter(evt);
        }
        return null;
    };
    handleEnter = (evt) => {
        if (evt.keyCode == 13) {
            if (!this.props.onEnter) {
                if (this.props.submitOnEnter) {
                    this.handleSave();
                }
            } else if (this.props.onEnter) {
                window.setTimeout(() => this.props.onEnter(), 500);
            }
        }
        return true;
    };
    handleClose = (evt) => {
        if (this.props.onClose) {
            this.props.onClose(this);
        }
    };
    handleDelete = (evt) => {
        if (this.props.onDelete) {
            this.props.onDelete(this);
        } else {
            this.handleSave(evt, {
                'delete-instance': 'on',
                'confirm': 'on',
            }).then(response => {
                const { success, __url__ } = response;
                if (success && __url__) {
                    this.props.focus.set_focus(null, __url__);
                }
            });
        }
    };
    handleError = (err, action_description, updates) => {
        var self = this;
        error_report(err, 'Error ' + action_description);
        var errors = [];

        if (err.status) {
            errors.push('' + err.statusText + ' ' + action_description);
        } else if (err.isRejected) {
            errors.push('Connection rejected ' + action_description);
        } else {
            errors.push(`Error ${action_description}: ${(err && err.message) || err || 'see above'}`);
        }
        self.safeSetState({
            'saving': false,
            'errors': errors,
            'show_success_or_error_msg': true,
            ...updates
        });
    };
    handleSave = (evt, parameters) => {
        /* NOTE(spditner/2019-07-30):
         *   This setTimeout is "similar yuck" to the delay added to onEnter.
         *   When you click on a "Submit" or similar button without having
         *   interacted with the page, the browser needs a moment to register
         *   this first interaction, and for the React change events to fire.
         *   Otherwise, the browser will submit no data.
         *
         *   Some discussion on "interaction" in this chromium issue:
         *   - https://bugs.chromium.org/p/chromium/issues/detail?id=352527
         */
        const { history } = this.props;
        if (this.props.handleSave) {
            return this.props.handleSave(this, parameters);
        }
        const promise = new Promise((resolve, reject) => {
            window.setTimeout(() => {
                var self = this;
                var target = self.props.target;
                var base = self.currentFormValues();
                if (parameters) {
                    $.extend(base, parameters);
                }
                self.safeSetState({ 'saving': true, 'show_success_or_error_msg': false });
                let storage = self.get_storage();
                let original_url = target && target.__url__;
                if (original_url != window.location.pathname) {
                    original_url = null;
                }
                return storage.save_form(
                    self.getFormKey(),
                    target.__pk__ || target.id,
                    base
                ).then(
                    (response) => {
                        self.handleSaveResponse(response, parameters);
                        resolve(response);
                        if (original_url && response.instance && response.instance.__url__) {
                            window.setTimeout(() => redirect(response.instance.__url__, history), 25);
                        }
                    },
                    (err) => {
                        self.handleSaveError(err, parameters);
                        reject(err);
                    }
                );
            }, 500);
        });
        return promise;
    };
    handleSaveResponse = (response, parameters) => {
        // console.log(`Save finished on form ${this.props}`);
        var self = this;
        const state_set = {
            'saving': false,
            'save_error': !response.success,
            'show_success_or_error_msg': true,
        };
        if (parameters && parameters.initial_form_post) {
            state_set.initial_post_response = true;
        }
        // self.safeSetState(update);
        // const state_set = {};
        if (response.form) {
            state_set.form_details = response.form;
        }
        if (response.message) {
            state_set['errors'] = [response.message];
        } else if (response.messages) {
            state_set['errors'] = response.messages;
        } else {
            state_set['errors'] = null;
        }
        if ((response && response.success)) {
            if (self.props.onSave) {
                self.props.onSave(self, response);
            }
            state_set.form_post = {};
            if (response.instance) {
                self.handleExternalChange(response.instance);
            }
        }
        self.safeSetState(state_set);
        return self;
    };
    handleSaveError = (err, parameters) => {
        const update = {};
        if (parameters && parameters.initial_form_post) {
            update.initial_post_response = true;
        }
        this.handleError(
            err,
            (parameters && parameters.busy_message) || 'Saving form',
            update
        );
        return err;
    };
    form_details = () => {
        return this.state.form_details || this.props.form_details;
    };
    get_storage = () => {
        /* Get our storage, allowing for setting storage to a string key instead of a storage instance */
        if (this.props.storage) {
            if (typeof this.props.storage == 'string') {
                return store_by_key(this.props.storage);
            } else {
                return this.props.storage;
            }
        }
        // TODO: nothing should currently be using this...
        return forms.storage;
    }
    get_field = (field_name) => {
        var field = null;
        var details = this.form_details();
        if (!details) {
            return null;
        }
        $.map(details.fields, (f) => {
            if (f.name == field_name) {
                field = f;
            }
        });
        return field;
    };
    construct_fields = (details) => {
        /* construct the fields defined in details */
        const { classes } = this.props;
        var self = this;
        details = details || self.form_details();
        var current_values = self.jsonFormValues();
        var contents = [[]];
        var multi_column = false;
        if (details.field_sets) {
            /* form defines sets of fields to be collected together */
            let field_sets = [...details.field_sets];
            var field_map = {};
            $.map(details.fields, (field) => {
                field_map[field.name] = field;
            });
            const assigned = {};
            field_sets.filter(x => !!x).map(field_set => {
                field_set.fields.map(field => assigned[field] = true)
            });
            const unassigned = details.fields.map(field => {
                if (!assigned[field.name]) {
                    console.log(`Field ${field.name} is not in any field-set for form ${this.getFormKey()}`);
                    return field;
                } else {
                    return null;
                }
            }).filter(x => !!x);
            const blocks = []
            if (unassigned.length) {
                field_sets.push({ 'key': 'unassigned', 'fields': unassigned.map(f => f.name), 'columns': 2 });
            }
            $.map(field_sets, (field_set, index) => {
                let some_field_not_hidden = false;
                var fields = $.map(field_set.fields, (field_name) => {
                    var field = field_map[field_name];
                    delete field_map[field_name];
                    let result = null;
                    if (field !== undefined) {
                        if (field && self.shouldShow(field, current_values)) {
                            some_field_not_hidden = true;
                        }
                        result = self.renderField(field, current_values);
                    } else {
                        var field_function = 'renderField_' + field_name;
                        var handler = self.props[field_function] || self[field_function];
                        if (handler) {
                            result = handler(self, field_name, details);
                        } else {
                            console.log("Fieldset declares unknown field " + field_name + ' ' + JSON.stringify(field_set));
                            result = null;
                        }
                    }
                    if (result) {
                        if (field_set.field_props && field_set.field_props[field_name]) {
                            result = <div key={`field-set-${index}-props`} {...field_set.field_props[field_name]}>{result}</div>;
                        }
                    }
                    return result;
                }).filter(x => (!!x));
                if (fields.length) {
                    const column_count = field_set.columns || 2;
                    const flow_class = (field_set && classes) ? classes[`fieldSetFlow${column_count}`] : null;
                    var holder = <div
                        className={classNames(some_field_not_hidden ? null : 'hidden', (classes && classes.fieldSet), 'field-set', 'field-set-' + field_set.key, (field_set.className ? field_set.className : null))}
                        key={field_set.key}>
                        {field_set.label && <h4 className={classes && classes.fieldSetLabel}>{field_set.label}</h4>}
                        <div className={classNames(classes && classes.fieldSetBody, flow_class)}>
                            {fields}
                        </div>
                    </div>;
                    blocks.push(holder);
                }
            });
            multi_column = true;
            contents = blocks;
        } else {
            contents = $.map(details.fields, (field) => {
                return self.renderField(field, current_values);
            });
        }
        return {
            'multi_column': multi_column,
            'children': contents
        };
    };
    form_instructions = () => {
        /* render form instructions if present */
        var self = this;
        var description = self.form_details() && self.form_details().instructions;
        if (description) {
            return render_html_struct(description, 'instructions');
        } else {
            return null;
        }
    };
    form_title = () => {
        return <BaseFormTitle
            {...this.props}
            form={this}
        />;
    };
    /* perform initial post if we are intended to and have sufficient information */
    do_initial = () => {
        var self = this;
        if (self.props.initial_post && (!self.state.initial_posted) && self.props.form_details) {
            self.safeSetState({
                'form_post': self.props.initial_post,
                'initial_posted': true
            });
            self.handleSave(null, {
                initial_form_post: true,
            });
        }
    };
}
/* Standard form-like renderer for a Reactform */

var ReactFormStandardRender = function () {
    var self = this;

    if (
        this.state
        && this.state.initial_post
        && (!this.state.initial_post_response)
    ) {
        return null;
    }

    var title = self.form_title();
    var actions = WrapFormActions(self.props.form_actions(self), self);

    var form_body = null;
    var details = self.form_details();

    var formClass = 'react-form form-body ' + (this.props.className ? this.props.className : '');
    var show_success_or_error_msg = this.state.show_success_or_error_msg && !this.state.saving;

    if (!details) {
        form_body = <div className={formClass} key="form-body" onKeyDown={self.handleKeyDown}>
            {this.state.errors ?
                <ErrorList errors={this.state.errors} /> :
                <div>
                    {getIcon('spinner spinner', 'Loading form from server')}
                    {' Loading'}
                </div>}
        </div>;
    } else {
        var contents = self.construct_fields();
        form_body = <div className={formClass} key="form-body">
            {this.state.errors &&
                <ErrorList errors={this.state.errors} />
            }
            {this.form_instructions()}
            {
                (details.form_errors && !!details.form_errors.length) &&
                <ErrorList errors={details.form_errors} />
            }
            {contents.children}
        </div>;
    }
    // console.log(`Rendering form`);
    var child_props = {
        title: title,
        controls: actions,
        className: this.props.className,
        onKeyDown: self.handleKeyDown,
        onClose: this.handleClose.bind(this),
        portalId: this.props.portalId,
    };

    if (this.props.use_dialog === false) {
        const success_details = {
            'success': !(this.state.errors && this.state.errors.length),
            'errors': this.state.save_error,
            'message': this.state.errors && this.state.errors.join(', '),
        };
        return (
            <div className="inline-form">
                {form_body}
                {actions}
                {
                    show_success_or_error_msg &&
                    <ReactFormSuccessOrError
                        details={success_details}
                        default_messages={this.props.default_messages}
                        on_close={() => this.safeSetState({ show_success_or_error_msg: false })}
                    />
                }

            </div>
        );
    } else {
        return <ReactDialog
            maxWidth={contents.multi_column ? 'lg' : 'sm'}
            {...child_props}
        >{form_body}</ReactDialog>;
    }
};


class BasePureForm extends BaseForm {
    /* mixin providing shouldComponentUpdate for pure-data forms */
    // shouldComponentUpdate( nextProps, nextState ) {
    //     if (shallow_compare(this.props,nextProps) && shallow_compare( this.state, nextState )) {
    //         return false;
    //     }
    //     return true;
    // }
    render() {
        return ReactFormStandardRender.bind(this)();
    }
}
const PureForm = using_nearest_form(with_bare_focus(withStyles(styles)(BasePureForm)));

/** Standard class for ReactForm usage */
class StandardReactForm extends BaseForm {
    render() {
        return ReactFormStandardRender.bind(this)();
    }
}
var ReactForm = using_nearest_form(with_bare_focus(ServerLoaded(withStyles(styles)(StandardReactForm))));


/** ReactForm that is a single unadorned field on the page */
class _ReactInlineForm extends BaseForm {
    static defaultProps = {
        ...BaseForm.defaultProps,
        use_dialog: false,
        form_details: null,
        onKeyDown: null,
        field: null,
        onChange: null,
        object_signals: null, // Dispatcher to which to listen for changes...
    };
    state = {
        saving: null,
        validating: null,
        initial_post: null,
        initial_post_done: false,
        initial_post_response: null,
        form_post: {},
        changed_signal: Signal(),
        initial_click: false,
    };
    current_widget = (field) => {
        /* Only return the widget if we are mounted */
        if (!this.mounted) {
            return null;
        }
        if (super.current_widget) {
            return super.current_widget(field);
        } else {
            return null;
        }
    };
    onFieldChanged = (record) => {
        console.log('Updated field ' + record.field.name + '=' + record.value);
        var name = record.field.name || record.field;
        var params = {
            //'validate_only': true,
            'restrict_fields': name
        };
        params[name] = record.value;
        if (!this.mounted) {
            // If we've already unmounted, remove the callback
            this.state.changed_signal.ignore(this.onFieldChanged);
        }
        return this.handleSave(null, params).then((result) => {
            const widget = this.current_widget(this.props.field);
            const current_field = this.get_field(this.props.field);
            const field_ref = this.field_reference(this.props.field);
            if (!result.error) {
                const widget = this.current_widget(this.props.field);
                current_field.errors = null;
                if (field_ref && field_ref.current) {
                    field_ref.current.set_value(current_field.value, true);
                }
                if (widget) {
                    widget.setState( // TODO: some safe setting of state...
                        {
                            changed: false,
                            saving: false,
                        }
                    );
                }
                if (result.form && this.mounted) {
                    this.setState({
                        'form_details': result.form,
                    });
                }
                if (result.instance && this.props.onChange) {
                    this.props.onChange(result.instance);
                }
            } else if (result.form) {
                let field = result.form.fields.filter(
                    f => (f.name == this.props.field)
                );
                if (field) {
                    field = field[0];
                } else {
                    field = widget.props.field;
                }
                if (result.form.form_errors && result.form.form_errors.length) {
                    field.errors = [
                        ...(field.errors || []),
                        ...result.form.form_errors,
                    ];
                    console.log(`Adding errors to the field from the backend ${JSON.stringify(field.errors)}`);
                }
                if (this.mounted) {
                    this.setState({
                        'form_details': result.form,
                    });
                }
                widget.setState(
                    {
                        saving: false,
                    }
                );
            }
            return result;
        });
    };
    componentDidMount() {
        // console.debug(`Binding inline form ${JSON.stringify(this.props.target)}`);
        this.mounted = true;
        this.state.changed_signal.listen(this.onFieldChanged);
        // TODO: this is encprofile specific and shouldn't be here...
        if (this.props.object_signals && this.props.target.__key__) {
            this.props.object_signals.listen(
                this.props.target.__key__, this.handleExternalChange
            );
        }
    }
    componentWillUnmount() {
        this.mounted = false;
        // this.state.changed_signal.ignore(this.onFieldChanged);
        if (this.props.object_signals && this.props.target.__key__) {
            this.props.object_signals.ignore(
                this.props.target.__key__, this.handleExternalChange
            );
        }
    }
    render() {
        const { target, initialClick } = this.props;
        const storage = this.get_storage();
        var field = this.get_field(this.props.field);
        if (!field) {
            return getIcon('spinner spinner', 'Loading form template');
        }
        return (
            <LoadingDisplay signal={storage.loading}>
                <div className="react-inline-form">
                    <ReactFormField
                        show_label={false}
                        changed_signal={this.state.changed_signal}
                        field={field}
                        widgets={this.props.widgets}
                        key={field.name}
                        ref={this.field_reference(field)}
                        autoFocus={true}
                        initialClick={initialClick}
                        form={this}
                        changed={target && target.changed}
                        onChange={(new_value) => {
                            this.fieldChanged(field, new_value);
                        }}
                        onBlur={this.props.onBlur}
                        onKeyDown={this.key_handler()}
                    />
                </div>
            </LoadingDisplay>
        );
    }
}
var ReactInlineForm = using_nearest_form(with_bare_focus(ServerTemplated(withStyles(styles)(_ReactInlineForm))));

/* Utility function to render a JSON structure into HTML

Used when declaring a form for use in ReactForm, this function
allows us to define an HTML fragment to be rendered via
React.
*/
var render_html_struct = function (struct, key) {
    var children = null;
    if (struct.children !== undefined && struct.children.length) {
        children = $.map(struct.children, function (child, index) {
            if (typeof child === 'string') {
                return (
                    <span key={'' + index}>
                        {child}
                    </span>
                );
            } else {
                return render_html_struct(child, '' + index);
            }
        });
    }
    var params = { key: key };
    if (struct.params) {
        $.extend(params, struct.params);
    }
    var TagName = struct.tag;
    return <TagName {...params}>{children}</TagName>;
};

function dependencies_true(field, current_values) {
    /* Are the field's dependencies met by the given record */
    if (field && field.dependencies) {
        //console.log("Dependencies: "+JSON.stringify( field.dependencies ));
        var matching = 0;
        $.map(field.dependencies, function (record) {
            var field_match = 0;
            let { key, compare } = field_comparison(record[0]);
            $.map(record[1], function (accept) {
                if (typeof record[1] == 'function') {
                    // Special case of a field that's custom-coded to have a functional comparison
                    if (record[1](current_values[key])) {
                        field_match = 1;
                    }
                } else if (current_values && compare(current_values[key], accept)) {
                    // console.log(`Match ${compare} ${current_values[key]} for ${accept}`);
                    field_match = 1;
                }
            });
            if (field_match) {
                matching += 1;
            }
        });
        // console.log(`Field ${field.name} ${matching} of ${field.dependencies.length}`);
        return (matching === field.dependencies.length);
    } else {
        return true;
    }
}

export {
    render_html_struct,
    form_action,

    construct_controls,
    default_cancel_button,
    default_save_button,
    dependencies_true,

    formFromTarget,
    ReactInlineForm,
    ReactFormField,
    ReactForm,
    ReactDialog,

    BaseForm,
    StandardReactForm,
    ServerLoaded,
    ServerTemplated,
    MultiTemplated,
    PureForm,
    ReactFormStandardRender
};
