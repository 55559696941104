/* Widget to show and allow for filtering out query parameters */
import React from 'react';
import useQuery from 'storages/usequery';
import { useLocation, useHistory } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import qs from 'query-string';
import { Redirect } from 'react-router-dom';
import { ToggleButton } from '@material-ui/lab';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useMetricFilters, { useFilterClearCallback } from 'storages/usemetricfilters';
import FilterList from '@material-ui/icons/FilterList';
import FilterNone from '@material-ui/icons/FilterNone';

const styles = (theme) => ({
    filterSet: {
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        backgroundColor: theme.roles.headers.filters,
        display: 'flex',
        flexDirection: 'row',
        borderTopLeftRadius: '.5rem',
        borderTopRightRadius: '.5rem',
    },
    chipSet: {
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        flex: 4,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
});
const query_to_url = (query, exclude = null) => {
    const tmp = {};
    for (let p of query) {
        if (p[0] !== exclude) {
            tmp[p[0]] = p[1];
        }
    }

    const redirect_target = `${location.pathname}?${qs.stringify(tmp)}`;
    return redirect_target;
};

const delete_callback = (query, setRedirect, key, value) => {
    const deleter = (evt) => {
        query.delete(key);
        setRedirect(query_to_url(query, key));
    };
    return deleter;
};
const set_callback = (query, setRedirect, key, value) => {
    const setter = (evt) => {
        query.set(key, value);
        setRedirect(query_to_url(query, null));
    };
    return setter;
};

const FilterSet = (props) => {
    const { classes, default_missing_rows = false } = props;
    // const [redirect, setRedirect] = React.useState(null);
    // if (redirect) {
    //     console.log(`Redirecting to ${redirect}`);
    //     return <Redirect to={redirect} />;
    // }

    const [filterSettings, setFilter] = useMetricFilters();
    const filterReset = useFilterClearCallback();
    console.log(`Rendering FilterSet with ${JSON.stringify(filterSettings)}`);


    // const location = useLocation();
    // const query = useQuery(location.search);
    let currentVisibility = filterSettings.create_missing_rows;
    const toggleVisibility = React.useCallback(
        (evt) => setFilter('create_missing_rows', !currentVisibility),
        [currentVisibility]
    );
    const chips = [
        <IconButton key='visibility-toggle' onClick={toggleVisibility}>{
            currentVisibility ?
                <Visibility titleAccess='Showing rows for not-reporting devices' /> :
                <VisibilityOff titleAccess='Hiding rows for not-reporting devices' />
        }</IconButton>,
    ];
    const keys = Object.keys(filterSettings).filter(x => x != 'create_missing_rows');
    keys.sort();
    keys.map((key) => {
        const value = filterSettings[key];
        chips.push(<Chip
            label={`${key}=${value}`}
            key={`${key}=${value}`}
            onDelete={() => setFilter(key, undefined)}
        />);

    });
    return <div className={classes.filterSet}>
        <IconButton color='secondary' size='small' aria-label="Filters" disabled key='list-marker'>
            <FilterList />
        </IconButton>
        <div className={classes.chipSet} key='chips'>
            {chips}
        </div>
        <IconButton color='secondary' size='small' aria-label="Reset Filters to Default" title="Reset Filters to Default" key='reset' onClick={filterReset}>
            <FilterNone />
        </IconButton>
    </div>;

};


export default withStyles(styles)(FilterSet);
