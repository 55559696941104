import { createTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

const spacingBaseline = 8;

const theme = createTheme({
    roles: {
        headers: {
            card: '#00447c50',
            table: '#00447c10',
            tableGroup: '#00447c07',
            legend: '#77a0c010',
            filters: '#00447c10',
        },
        footers: {
            card: '#00447c50',
            table: '#00447c10',
        },
        lines: {
            separators: '#00447c50',
        },
        errorLevel: {
            critical: colors.red[700],
            error: colors.red[500],
            warning: colors.amber[700],
            info: colors.green[500],
            debug: colors.grey[300],
        },
        info: {
            background: colors.grey[100],
            foreground: 'black',
        },
    },
    palette: {
        primary: {
            hint: '#00447c15',
            shadow: '#00447c25',
            shading: '#00447c50',
            light: '#1695fe',
            main: '#00447c',
            dark: '#03080d',
            very: '#010407',
        },
        secondary: {
            main: '#026f80',
        },
        common: {
            main: colors.grey[900],
            text: colors.grey[900]
        },
        critical: { main: colors.red[700] },
        error: { main: colors.red[500] },
        warning: { main: colors.amber[700] },
        info: { main: colors.green[500] },
        debug: { main: colors.grey[300] },

        success: { main: colors.green[700] },
        red: { main: colors.red[500] },
        green: { main: colors.green[500] },
        orange: { main: colors.orange[500] },
        purple: { main: colors.purple[500] },
        teal: { main: colors.teal[500] },
        disabled: { main: colors.grey[300] },
    },
    icons: {
        'delete': 'close',
        'disable': 'highlight_off',
        'edit': 'edit',
        'add': 'add',
        'success': 'check_circle',
        'warning': 'warning',
        'error': 'error',
        'info': 'info',
        'bool_true': 'check_box',
        'bool_false': 'check_box_outline_blank',
        'bool_unknown': 'indeterminate_check_box',
        'check': 'check',

        'check_circle': 'check_circle',
        'remove_circle': 'remove_circle',
        'check_box': 'check_box',
        'check_box_outline_blank': 'check_box_outline_blank',
        'indeterminate_check_box': 'indeterminate_check_box',
        'circle': 'radio_button_unchecked',
        'thumb_up': 'thumb_up',
        'thumbs_up': 'thumb_up',
        'remove': 'remove',
        'break_link': 'unlink',
        'check': 'check',
        'close': 'close',
        'phone_link_off': 'phone_link_off',
        'expand_less': 'expand_less',
        'expand_more': 'expand_more',
        'bug': 'bug_report',
        'deploy': 'publish',
        'ping': 'signal',
        'replay': 'replay',
        'poweroff': 'poweroff',
        'download': 'cloud_download',
    },
    status: {
        danger: 'orange'
    },
    sidebarWidth: 180,
    spacing: spacingBaseline,
    toolbar: {
        height: '64px'
    },
    typography: {
        fontWeightBold: 900,
    },
    spacingBaseline: spacingBaseline,
});

export default theme;
