import React from 'react';
import { WithTabularStorage } from 'storages/tabularstorage';
import MuiLoader from 'dash/MuiLoader';
import { ReactFormTable } from 'reactform';
import { delete_target_action } from 'reactform/actionmenu';
import { useCurrentUser } from 'withcurrentuser';

const BaseFirmwareTable = (props) => {
    const { form_details, product_type } = props;
    const user = useCurrentUser();
    if (!(form_details)) {
        return <MuiLoader />;
    }
    const editable = user && user.has_permission('shogunconf.edit_firmware');
    const field_actions = editable ? {
        'name': (target, context, menu) => {
            return [
                delete_target_action(target, context, menu),
            ];
        }
    } : {};
    return <ReactFormTable
        wrap_data={(record, table) => {
            return [record,];
        }}
        editable={editable}
        extra_fields={{
            'Config': [
                {
                    'name': 'target', 'label_id': 'id_current', 'label': 'Target Config For',
                    'widget': 'DisplayWidget',
                    'field': {
                        'help_text': 'Shows the number of resources to which the firmware is assigned',
                    }
                }
            ]
        }}
        exclude={[]}
        form_details={form_details}
        overall_target={{
            id: product_type,
            __type__: 'ProductType',
            __pk__: product_type,
        }}
        field_actions={field_actions}
        {...props}
    />;

}

const firmware_storage = (product_type = null) => {
    /* Get a product_type-specific storage for given product_type *or* all firmwares */
    const default_context = {};
    if (product_type) {
        default_context['product_type'] = product_type;
    }
    return {
        default_context: default_context,

        forms_key: 'shogunconf_forms',
        form_key: 'ShogunFirmware',
        form_keys: ['Firmware'],

        product_type: product_type,
        quick_settings: {
        },
    };
};


const FirmwareTable = (props) => {
    return <WithTabularStorage
        key={`firmwares-for-type-${props.storage_props.default_context.product_type || 'all'}`}
        storage_props={props.storage_props}
        Component={BaseFirmwareTable}
        product_type={props.storage_props.default_context.product_type}
        preference_key='firmware-uploaded'
        preference_default={{
            hiddenColumns: [
                'hash',
                'owner',
                'md5',
            ]
        }}
        defaultFilters={[
            { "id": "ga_release", "value": "true" }
        ]}
        widgets={{
            "file": "FileDownload",
            ...(props.widgets || {}),
        }}

        form_keys={['FirmwareView']}
        {...props}
    />;
};
export default FirmwareTable;
export { firmware_storage, BaseFirmwareTable, FirmwareTable };
